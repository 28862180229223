export function checkWebPSupport(): boolean {
	if (process.env.JEST_WORKER_ID !== undefined) {
		return false;
	}

	// Use canvas hack for webkit-based browsers
	// Kudos to Rui Marques: https://stackoverflow.com/a/27232658/7897049
	const e = document.createElement('canvas');
	if (e.toDataURL && e.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
		return true;
	}

	// Check other common browsers by version
	const m = navigator.userAgent.match(/(Edg|Firefox)\/(\d+)\./);
	if (m) {
		return (
			(m[1] === 'Firefox' && Number(m[2]) >= 65) || (m[1] === 'Edge' && Number(m[2]) >= 18)
		);
	}

	const m2 = navigator.userAgent.match(/OS X\s?(?<os>\d+)?.+ Version\/(?<v>\d+\.\d+)/);
	if (m2 && m2.groups) {
		return Number(m2.groups.v) >= 14 && Number(m2.groups.os || 99) >= 11;
	}

	return false;
}

export const hasWebPSupport = checkWebPSupport();
export const fileEndingWebpOrJpeg = hasWebPSupport ? 'webp' : 'jpg';
