import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorPage from '@components/pages/ErrorPage';
import StartPage from './pages/StartPage/StartPage';
import ThankYouPage from './pages/ThankYouPage';
import ChooseTeamPage from './pages/ChooseTeamPage';
import KlarnaPageSubscription from './pages/KlarnaPage/KlarnaPageSubscription';
import TopBar from '@components/TopBar';
import Footer from '@components/Footer';

function StartSubscriptionFlow(): JSX.Element {
	return (
		<>
			<TopBar />
			<Routes>
				<Route path="/" element={<StartPage />} />
				<Route path="/choose-team" element={<ChooseTeamPage />} />
				<Route path="/choose-team/:abbrv" element={<ChooseTeamPage />} />
				<Route path="/payment" element={<KlarnaPageSubscription />} />
				<Route path="/payment/:abbrv" element={<KlarnaPageSubscription />} />
				<Route path="/thank-you" element={<ThankYouPage />} />
				{/* <Route path="/thank-you/:orderId" element={<ThankYouPage />} />
				<Route path="/thank-you/:orderId/:abbrv" element={<ThankYouPage />} /> */}
				<Route path="*" element={<ErrorPage status={404} />} />
			</Routes>
			<Footer />
		</>
	);
}

export default StartSubscriptionFlow;
