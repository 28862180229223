import * as React from 'react';
import styled from 'styled-components';

type StyledSvgProps = {
	size: number;
};
const StyledSvg = styled.svg<StyledSvgProps>`
	${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
`;

export type IconSizeProps = {
	size?: 'small' | 'medium' | 'big';
};

export const getSizeInPx = (size?: 'small' | 'medium' | 'big') => {
	return size && size === 'big' ? 74 : size && size === 'medium' ? 24 : 13;
};

export type IconProps = IconSizeProps & React.SVGAttributes<SVGElement>;
export const Icon = ({ size, ...props }: IconProps) => {
	const sizeInPx = getSizeInPx(size);
	return (
		<StyledSvg
			xmlns="http://www.w3.org/2000/svg"
			fill="currentcolor"
			size={sizeInPx}
			{...props}
		/>
	);
};

export const HomeIcon = (props: IconProps) => (
	<Icon viewBox="0 0 13 13" {...props}>
		<path fill="none" d="M0,0H13V13H0Z" />
		<path
			d="M11.22,11.281a.535.535,0,0,1-.538.532H3.152a.535.535,0,0,1-.538-.532V6.489H1l5.555-5a.542.542,0,0,1,.724,0l5.555,5H11.22Z"
			transform="translate(-0.417 -0.563)"
		/>
	</Icon>
);

export const MailIcon = (props: IconProps) => (
	<Icon viewBox="0 0 13 13" {...props}>
		<path fill="none" d="M0,0H13V13H0Z" />
		<path
			d="M2.533,3h9.6a.531.531,0,0,1,.533.528v8.444a.531.531,0,0,1-.533.528h-9.6A.531.531,0,0,1,2,11.972V3.528A.531.531,0,0,1,2.533,3ZM7.365,7.583,3.946,4.709l-.691.8,4.117,3.46,4.043-3.462-.7-.8L7.366,7.583Z"
			transform="translate(-0.833 -1.25)"
		/>
	</Icon>
);

export const SelectArrow = (props: IconProps) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<path fill="none" d="M0,0H24V24H0Z" />
		<path d="M12,14,8,10h8Z" />
	</Icon>
);

export const ExpandArrow = (props: IconProps) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
	</Icon>
);

export const CloseIcon = (props: IconProps) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<path fill="none" d="M0,0H24V24H0Z" />
		<path d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" />
	</Icon>
);

export const TickIcon = (props: IconProps) => (
	<Icon viewBox="0 0 24 24" {...props} stroke="currentcolor">
		<path d="M4 12L9.65685 17.6568M8.58579 17.3137L19.8995 6" strokeWidth="2" />
	</Icon>
);

export const AlertIcon = (props: IconProps) => (
	// <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" viewBox="0 0 24 24" >
	<Icon viewBox="0 0 24 24" {...props} fill="#f44336">
		<path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
	</Icon>
);

export const Loading = (props: IconProps) => (
	<Icon viewBox="0 0 74 73.999" {...props}>
		<g transform="translate(0)">
			<path
				d="M157.912,83.606h-9.054a5.656,5.656,0,1,0,0,11.312h9.054a5.656,5.656,0,0,0,0-11.312Z"
				transform="translate(-89.568 -52.263)"
			/>
			<path
				d="M20.366,89.262a5.656,5.656,0,0,0-5.656-5.656H5.656a5.656,5.656,0,1,0,0,11.312H14.71A5.656,5.656,0,0,0,20.366,89.262Z"
				transform="translate(0 -52.263)"
			/>
			<path
				d="M88.929,143.2a5.656,5.656,0,0,0-5.656,5.656v9.054a5.656,5.656,0,0,0,11.312,0v-9.054A5.656,5.656,0,0,0,88.929,143.2Z"
				transform="translate(-52.054 -89.567)"
			/>
			<path
				d="M88.929,0a5.656,5.656,0,0,0-5.656,5.656v9.053a5.656,5.656,0,0,0,11.312,0V5.657A5.656,5.656,0,0,0,88.929,0Z"
				transform="translate(-52.054 -0.001)"
			/>
			<path
				d="M135.4,127.4a5.656,5.656,0,0,0-8,8l6.4,6.4a5.656,5.656,0,0,0,8-8Z"
				transform="translate(-78.694 -78.695)"
			/>
			<path
				d="M34.144,26.144a5.656,5.656,0,1,0-8,8l6.4,6.4a5.656,5.656,0,0,0,8-8Z"
				transform="translate(-15.255 -15.254)"
			/>
			<path
				d="M32.545,127.4l-6.4,6.4a5.656,5.656,0,0,0,8,8l6.4-6.4a5.656,5.656,0,0,0-8-8Z"
				transform="translate(-15.254 -78.694)"
			/>
			<path
				d="M131.4,42.2a5.637,5.637,0,0,0,4-1.657l6.4-6.4a5.656,5.656,0,1,0-8-8l-6.4,6.4a5.656,5.656,0,0,0,4,9.656Z"
				transform="translate(-78.693 -15.253)"
			/>
		</g>
	</Icon>
);
