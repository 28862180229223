import { KlarnaPaymentsTypes } from '../types.d';

/**
 * Klarna Payments session class.
 */
export default class KlarnaPaymentsSession {
	sessionId: string;

	clientToken: string;

	paymentMethodCategories: KlarnaPaymentsTypes.PaymentMethodCategory[];

	constructor(sessionId: string, clientToken: string) {
		this.sessionId = sessionId;
		this.clientToken = clientToken;
		this.paymentMethodCategories = [];
	}

	addPaymentMethodCategory(
		category: KlarnaPaymentsTypes.PaymentMethodCategory
	): KlarnaPaymentsSession {
		this.paymentMethodCategories.push(category);
		return this;
	}
}
