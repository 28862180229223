export type ColorScheme = 'light' | 'dark';

export type Colors = Record<ColorScheme, string>;

export const dividerColors: Colors = {
	dark: '#8D8D8D',
	light: '#EDE9F4',
};

export const insertColors: Colors = {
	dark: '#262626',
	light: '#F1F1F1',
};

export const disabledColors: Colors = {
	dark: '#FFFFFF1F',
	light: '#0000001F',
};

export interface ClubPalette {
	lightMode: boolean;
	accent: string;
	primary: string;
	contrast: string;
	topbar: string;
	topbarBorder?: string;
	topbarTextColor: string;
	bgColor: string;
	textColor: string;
	cta?: string;
}

export interface Palette extends ClubPalette {
	accentDark: string;
	accentLight: string;
	allsvenskan: string;
	superettan: string;
	divider: string;
	insertBackground: string;
	disabledBackground: string;
	fields: {
		background: string;
		backgroundDisabled: string;
		border: string;
		text: string;
		textDisabled: string;
	};
	selectOption: {
		backgroundActive: string;
		hover: string;
		selected: string;
	};
}

export const defaultPalette: Palette = {
	lightMode: true,
	textColor: '#000000',
	bgColor: '#FFFFFF',
	accent: '#532681',
	accentDark: '#341058',
	accentLight: '#f7f7f7',
	contrast: 'red', // TEMP DEBUG
	primary: '#FAF9FC',
	topbar: '#532681',
	topbarBorder: '#75C0E3',
	topbarTextColor: '#FFFFFF',
	allsvenskan: '#0A135D',
	superettan: '#006c25',
	cta: '#198754',
	divider: dividerColors.light,
	insertBackground: insertColors.light,
	disabledBackground: disabledColors.light,
	fields: {
		background: '#F6F4F9',
		backgroundDisabled: '#E9E9E9',
		border: '#949494',
		text: '#333',
		textDisabled: '#4C4C4C',
	},
	selectOption: {
		hover: '#F6F4F9',
		selected: '#E9E9E9',
		backgroundActive: '#E9E9E9',
	},
};

export const fonts: {
	BOLD: { css: string; obj: { fontFamily: string; fontWeight: 'normal' | 'bold' } };
} = {
	BOLD: {
		css: `
			font-family: 'Gilroy-Bold';
			font-weight: normal;
		`,
		obj: {
			fontFamily: 'Gilroy-Bold',
			fontWeight: 'normal',
		},
	},
};
