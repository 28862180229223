import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Logger } from '@utils/logger';
import { useApp } from '@contexts/app';
import KlarnaCard from '@components/KlarnaCard';
import Container from '@components/Container';
import Spinner from '@components/Spinner';
import Card from '@components/Card';
import { useProduct } from '@contexts/product';
import { KlarnaPaymentsTypes } from '@klarna/payments/types';
import CEE from '@api/cee/ceeV3';
import { CeeNewKlarnaIntegrationError } from '@api/cee/error';
import KlarnaAlert from '@components/KlarnaAlert';
import HighlightsPlusInfoCard from '../../components/HighlightsPlusInfoCard';
import { AlertError } from '../../../../types/types.d';
import { ErrorMessages } from '@data/constants';
import useOrder, { OrderType } from '@hooks/useOrder';
import { logInfo, logError } from '@utils/logtail';

const logger = new Logger('KlarnaPageSubscription');

const { REACT_APP_HIGHTLIGHTS_PLUS_V3_ID } = process.env as Record<string, string>;

const KlarnaPageSubscription = () => {
	const { abbrv } = useParams<{
		abbrv: string;
	}>();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { tokens, setCEEOrder } = useApp();
	const { productId, product, setProductId, hasAccessToProduct } = useProduct();
	const [error, setError] = useState<AlertError | null>(null);

	const acceptNewsLetter = searchParams.get('acceptNewsLetter') === 'on';
	const orderType: OrderType = hasAccessToProduct ? 'update' : 'new';

	useEffect(() => {
		if (!productId) {
			setProductId(REACT_APP_HIGHTLIGHTS_PLUS_V3_ID);
		}
	}, [productId, setProductId]);

	const [order, orderLoading, orderError] = useOrder(orderType, product, acceptNewsLetter, abbrv);

	useEffect(() => {
		if (orderError && !error) {
			setError(orderError);
		}
	}, [orderError, error]);

	// console.log('acceptNewsLetter:', acceptNewsLetter);
	// console.log('abbrv:', abbrv);
	// console.log('KlarnaPageSubscription - productId:', productId);
	// console.log('KlarnaPageSubscription - product:', product);
	// console.log('KlarnaPageSubscription - abbrv:', abbrv);
	// console.log('KlarnaPageSubscription - acceptedNews:', acceptNewsLetter);
	// console.log('KlarnaPageSubscription - orderType', orderType);
	// console.log('KlarnaPageSubscription - order', order);
	// console.log('KlarnaPageSubscription - orderLoading', orderLoading);
	// console.log('KlarnaPageSubscription - orderError', orderError);

	const onKlarnaOrderCompleted = async (
		authorizationResponse: KlarnaPaymentsTypes.AuthorizeResponseData
	) => {
		if (!tokens || !order) {
			return;
		}

		const { authorization_token: klarnaToken } = authorizationResponse;

		try {
			if (!klarnaToken) {
				throw new Error('Klarna authorization not completed');
			}

			if (orderType === 'new') {
				await CEE.completeOrder(
					tokens.PersonId,
					tokens.accessToken,
					klarnaToken,
					order.ceeOrder.Id,
					order.paymentInfo
				);
				logger.debug('Order completed in CEE!');
				logInfo('H+ subscription order completed in CEE!');
				setCEEOrder(order.ceeOrder);
				navigate(
					{
						pathname: `/subscription/thank-you`,
						search: searchParams.toString(),
					},
					{
						replace: true,
					}
				);
			} else {
				await CEE.completeChangePayment(
					tokens.PersonId,
					tokens.accessToken,
					klarnaToken,
					order.ceeOrder.Id,
					order.paymentInfo
				);
				logger.debug('Change payment completed in CEE!');
				logInfo('H+ subscription change payment completed in CEE!');
				setCEEOrder(order.ceeOrder);
				navigate(
					{
						pathname: `/subscription/thank-you`,
						search: searchParams.toString(),
					},
					{
						replace: true,
					}
				);
			}
		} catch (err: unknown) {
			console.error('Completing order failed!', err);
			logError('Completing order failed!', { errorMessage: error });
			if (err instanceof CeeNewKlarnaIntegrationError) {
				const ceeError = err.getCeeIntegrationError();
				console.log('CEE Integration Error:', ceeError);
				setError({
					...(ceeError?.ceeError?.Details?.PaymentProviderError
						? ErrorMessages.klarna
						: ErrorMessages.default),
					ref: ceeError?.ceeError?.CorrelationId,
				});
				Sentry.withScope((scope) => {
					scope.setExtra('CEE Error', ceeError);
					Sentry.captureException(err);
				});
			} else {
				Sentry.captureException(err);
				setError(ErrorMessages.default);
			}
			throw err;
		}
	};

	return (
		<Container role="document">
			{product && <HighlightsPlusInfoCard />}
			<Card contentMaxWidth={606} leftAligned paddingTop={60} paddingBottom={60} flex>
				{error && <KlarnaAlert error={error} />}
				{orderLoading && (
					<Card>
						<Spinner />
					</Card>
				)}
				{order && product && !orderLoading && !orderError && (
					<KlarnaCard
						order={order}
						product={product}
						buttonText={
							orderType === 'new' ? 'Slutför beställningen' : 'Ändra betalningsmetod'
						}
						onKlarnaOrderCompleted={onKlarnaOrderCompleted}
						setError={setError}
					/>
				)}
			</Card>
		</Container>
	);
};

export default KlarnaPageSubscription;
