import { useEffect, useCallback, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import CEE from '@api/cee/ceeV3';
import { CEETypes } from '@api/cee/types';
import { CeeNewKlarnaIntegrationError } from '@api/cee/error';
import { ErrorMessages } from '@data/constants';
import { AlertError } from '../types/types';
import { useApp } from '@contexts/app';
import useTeam from './useTeam';

export interface Order {
	ceeOrder: CEETypes.CEEOrder;
	paymentInfo: CEETypes.CEEOrderPaymentInfo;
	orderIdempotencyId: string;
}

export type OrderType = 'new' | 'update';

export default function useOrder(
	orderType: OrderType,
	product: CEETypes.CEEProduct | null,
	acceptMarketing: boolean,
	favoriteTeamAbbrv?: string
) {
	const { tokens } = useApp();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<AlertError | null>(null);
	const [order, setOrder] = useState<Order | null>(null);
	const initOnce = useRef<boolean>(false);
	const orderIdempotencyId = useRef(uuidv4());

	const [favoriteTeam, favoriteTeamLoading] = useTeam(favoriteTeamAbbrv);

	const createOrder = useCallback(async () => {
		if (tokens && product && !favoriteTeamLoading) {
			try {
				const sessionOrder = await CEE.createOrderRequest(
					tokens.PersonId,
					tokens.accessToken,
					product.Id,
					product.PricePlans[0].Id,
					orderIdempotencyId.current,
					acceptMarketing,
					favoriteTeam?.everySportId
				);
				setOrder({
					ceeOrder: sessionOrder.Order,
					paymentInfo: sessionOrder.PaymentInfo,
					orderIdempotencyId: orderIdempotencyId.current,
				});
			} catch (err: unknown) {
				console.error('Creating order failed!', err);
				throw err;
			} finally {
				setLoading(false);
				initOnce.current = true;
			}
		}
	}, [tokens, product, favoriteTeamLoading, acceptMarketing, favoriteTeam?.everySportId]);

	const updateOrder = useCallback(async () => {
		if (tokens && product && !favoriteTeamLoading) {
			try {
				const personProduct = await CEE.fetchPersonProduct(
					tokens.accessToken,
					tokens.PersonId,
					product.Id
				);
				const paymentInfo = await CEE.fetchNewPaymentInfo(
					tokens.accessToken,
					tokens.PersonId,
					personProduct.OrderId
				);
				const ceeOrder = await CEE.fetchOrder(
					tokens.accessToken,
					tokens.PersonId,
					personProduct.OrderId
				);
				setOrder({
					ceeOrder: ceeOrder,
					paymentInfo: paymentInfo,
					orderIdempotencyId: orderIdempotencyId.current,
				});
			} catch (err: unknown) {
				console.error('Update order failed!', err);
				throw err;
			} finally {
				setLoading(false);
				initOnce.current = true;
			}
		}
	}, [product, tokens, favoriteTeamLoading]);

	useEffect(() => {
		async function initOrder() {
			if (!initOnce.current) {
				try {
					if (orderType === 'new') {
						await createOrder();
					} else if (orderType === 'update') {
						await updateOrder();
					}
				} catch (err) {
					if (err instanceof CeeNewKlarnaIntegrationError) {
						const ceeError = err.getCeeIntegrationError();
						setError({
							...ErrorMessages.default,
							ref: ceeError?.ceeError?.CorrelationId,
						});
						Sentry.withScope((scope) => {
							scope.setExtra('CEE Error', ceeError);
							Sentry.captureException(err);
						});
					} else {
						Sentry.captureException(err);
						setError(ErrorMessages.default);
					}
				}
			}
		}
		initOrder();
	}, [orderType, product, tokens, createOrder, updateOrder]);

	return [order, loading, error] as [Order | null, boolean, AlertError | null];
}
