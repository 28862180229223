import React from 'react';
import LoadingImage from '@images/loading.svg';
import styled, { css } from 'styled-components';

const Img = styled.img<{
	size: number;
}>`
	display: inline-block;
	margin: 0;
	padding: 0;
	animation-name: spin;
	animation-duration: 2500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	${(props) =>
		props.size
			? css`
					width: ${props.size * 37}px;
					height: ${props.size * 37}px;
					@media (max-width: 640px) {
						width: ${props.size * 20}px;
						height: ${props.size * 20}px;
					}
			  `
			: ''}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

/**
 * Spinner component.
 * TODO: Describe component.
 */
export default function Spinner({
	size = 2,
}: React.PropsWithoutRef<{ size?: number }>): JSX.Element {
	return <Img src={LoadingImage} alt="" size={size} />;
}
