import React from 'react';
import styled from 'styled-components';
import { SEF } from '@data/constants';
import { ReactComponent as LogoSrc } from '@images/fotbollskonto-logo-pos.svg';
import { ReactComponent as EmailIconSrc } from '@images/email.svg';
import { ReactComponent as HomeIconSrc } from '@images/home.svg';

const MOBILE_MAX_WIDTH = '919px';

const StyledFooter = styled.footer`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	font-size: 14px;
	text-align: center;
	font-size: 13px;
	@media (max-width: 640px) {
		font-size: 12px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	padding: 14px 0;
	background-color: ${(props) => props.theme.topbar};
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const Item = styled.div`
	height: 25px;
	line-height: 28px;
	padding: 0 30px;
	border-left: 1px solid ${(props) => props.theme.accent};
	font-family: Gilroy-Bold;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	text-transform: uppercase;
	color: ${(props) => props.theme.topbarTextColor};
	&:first-child {
		border-left: none;
	}

	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		height: 19px;
		line-height: 19px;
		padding: 0 13px;
		font-size: 10px;
		&:last-child {
			display: none;
		}
	}
`;

const Logo = styled(LogoSrc)`
	width: 137px;
	height: 25px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: 104px;
		height: 19px;
	}
	.icon {
		fill: ${(props) => props.theme.topbarTextColor};
	}
`;

const EmailLink = styled.a`
	display: inline-block;
	padding: 0;
	text-decoration: none;
	color: ${(props) => props.theme.topbarTextColor};
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: 25px;
		height: 25px;
		margin-top: -4px;
		border: 1px solid #ede9f4;
		border-radius: 50%;
		font-size: 0;
	}
`;

const EmailIcon = styled(EmailIconSrc)`
	position: relative;
	top: 2px;
	width: 13px;
	height: 13px;
	margin-right: 9px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		top: 0;
		margin-top: 6px;
		margin-right: 0;
	}
	.icon {
		fill: ${(props) => props.theme.topbarTextColor};
	}
`;

const SefLink = styled.a`
	text-decoration: none;
	color: ${(props) => props.theme.topbarTextColor};
`;

const HomeIcon = styled(HomeIconSrc)`
	position: relative;
	top: 3px;
	width: 14px;
	height: 14px;
	margin-right: 9px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		display: none;
	}
	.icon {
		fill: ${(props) => props.theme.topbarTextColor};
	}
`;

/**
 * Footer component that's always located at the bottom of the page.
 * It contains contact links and some info.
 */
export default function Footer(): JSX.Element {
	return (
		<StyledFooter role="contentinfo">
			<Container>
				<Item>
					<Logo />
				</Item>
				<Item>{SEF.copyright}</Item>
				<Item>
					<EmailLink href={`mailto:${SEF.supportEmail}`} target="_blank">
						<EmailIcon />
						{SEF.supportEmail}
					</EmailLink>
				</Item>
				<Item>
					<SefLink href={SEF.url} target="_blank">
						<HomeIcon />
						{SEF.urlShort}
					</SefLink>
				</Item>
			</Container>
		</StyledFooter>
	);
}
