/**
 * Configuration for Klarna API.
 */
export default {
	/**
	 * URL to Klarna API lib.
	 */
	libSrc: 'https://x.klarnacdn.net/kp/lib/v1/api.js',

	/**
	 * ID for script element (handy).
	 */
	scriptId: 'klarna-api-script',

	/**
	 * Base URL for live (production) in Europe.
	 */
	liveBaseUrl: 'https://api.klarna.com/',

	/**
	 * Base URL for testing (playground) in Europe.
	 */
	testBaseUrl: 'https://api.playground.klarna.com/',

	/**
	 * Approved private person data for testing.
	 */
	approvedTestPerson: {
		id: '410321-9202',
		firstName: 'Testperson-se',
		lastName: 'Approved',
		street: 'Stårgatan 1',
		zipCode: '12345',
		city: 'Ankeborg',
		phoneNumber: '0765260000',
		email: 'youremail@email.com',
	},

	/**
	 * Denied private person data for testing.
	 */
	deniedTestPerson: {
		id: '411028-8083',
		lastName: 'Denied',
		firstName: 'Testperson-se',
		street: 'Stårgatan 1',
		zipCode: '12345',
		city: 'Ankeborg',
		phoneNumber: '0765260000',
		email: 'youremail+denied@email.com',
	},
};
