import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { H4, P } from '@components/Parts';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useApp } from '@contexts/app';
import { useProduct } from '@contexts/product';
import { useBranding } from '@contexts/branding';
import HuddleImage from '../../components/HuddleImage';
import CtaButton from '@components/CtaButton';
import MerchantInfo from '@components/MerchantInfo';
import useRedirect from '@hooks/useRedirect';
import { FLOWS } from '../../../../../types/types.d';
import LoadingCard from '@components/LoadingCard';

const { REACT_APP_MY_PAGE_URL, REACT_APP_FOTBOLL_PLAY_URL } = process.env;

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
`;

const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-top: 30px;
	padding-right: 20px;
	padding-left: 20px;
	max-width: 500px;
`;

const Header = styled(H4)`
	text-align: center;
	color: ${(props) => props.theme.textColor};
	margin-bottom: 30px;
`;

const Text = styled(P)`
	text-align: center;
	font-size: 14px;
	color: ${(props) => props.theme.textColor};
	& > a {
		color: ${(props) => props.theme.textColor};
		font-family: Gilroy-Regular;
	}
	& > b {
		font-family: Gilroy-Bold;
	}
	@media (max-width: 640px) {
		text-align: left;
	}
`;

const TextInfo = styled(Text)`
	font-size: 13px;
`;

const TextContainer = styled.div`
	text-align: left;
	margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-bottom: 30px;
`;

const FotbollPlayButton = styled(CtaButton)`
	margin-bottom: 30px;
`;

const MyPageButton = styled(CtaButton)`
	background-color: transparent;
	border: 2px solid #eaeaea;
	color: #101010;
`;

const Button = styled(CtaButton)`
	margin-bottom: 30px;
`;

function ThankYouPage(): JSX.Element {
	const { teamName } = useParams<{
		teamName: string;
	}>();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { redirectIfMissingProductId } = useRedirect();
	const { errorMessageConfig, ceeOrder, signOut } = useApp();
	const { teamAbbrv, merchant, strings } = useBranding();
	const { product, productId, redirectUrl } = useProduct();
	const [isSigningOut, setIsSigningOut] = useState(false);

	const alreadyAccess = searchParams.get('alreadyAccess') === '1';
	const orderId = ceeOrder?.OrderNumber || '';
	const orderDate = moment(ceeOrder?.CreatedAt).format('YYYY-MM-DD [kl] HH:mm') || '';
	const price = ceeOrder?.TotalAmount;
	const vatAmount = ceeOrder?.TotalVatAmount;
	const productName = product?.Name;
	const currency = product?.PricePlans[0]?.PriceCurrency;
	const nameOrAbbrv = merchant?.name || teamAbbrv || '';
	const supportEmail = merchant?.supportEmail || '';
	// TEMP Disabled for now
	const includesHighlightsPlus = false; //product?.Custom2 === 'HighlightsPlusIncluded';

	useEffect(() => {
		if (!productId) {
			redirectIfMissingProductId(FLOWS.CLUB_SUBSCRIPTION, productId);
		}
	}, [productId, redirectIfMissingProductId]);

	function handleFotbollPlayClick() {
		window.location.href = redirectUrl || (REACT_APP_FOTBOLL_PLAY_URL as string);
	}

	function handleMyPageClick() {
		window.location.href = REACT_APP_MY_PAGE_URL as string;
	}

	function handleChangePaymentClick() {
		navigate(
			{ pathname: `/${teamName}/subscription/payment`, search: searchParams.toString() },
			{ replace: true }
		);
	}

	async function handleChangeAccountClick() {
		if (!productId) {
			return;
		}
		setIsSigningOut(true);
		const signOutRedirectUrl = new URL(`${teamName}/subscription`, window.location.origin);
		signOutRedirectUrl.searchParams.set('productid', productId);
		await signOut(signOutRedirectUrl.toString());
	}

	return (
		<Container role="document">
			<HuddleImage abbrv={teamAbbrv || ''} />
			<Content>
				{isSigningOut ? (
					<LoadingCard text="Du loggas ut" />
				) : (
					<>
						{alreadyAccess ? (
							<Header>Du har redan tillgång till {productName}</Header>
						) : (
							<>
								<Header>Tack för ditt köp hos {ceeOrder?.Merchant.Name}</Header>
								<TextContainer>
									<Text>
										Produktnamn: <b>{productName}</b>
									</Text>
									<Text>
										Pris:
										<b>{` ${price} ${currency} varav moms ${vatAmount} ${currency}`}</b>
									</Text>
								</TextContainer>
								<TextContainer>
									{strings?.subscriptionPremium?.orderProductInformation && (
										<TextInfo
											style={{ marginBottom: 20 }}
											dangerouslySetInnerHTML={{
												__html: strings.subscriptionPremium.orderProductInformation
													.replace('$orderId', orderId)
													.replace('$orderDate', orderDate)
													.replace('$supportEmail', supportEmail)
													.replace('$clubName', nameOrAbbrv)
													.replace(
														'$subscriptionInfo',
														includesHighlightsPlus
															? strings.subscriptionPremium
																	.highlightsPlusInformationFirst
															: ''
													),
											}}
										></TextInfo>
									)}
									{includesHighlightsPlus &&
										strings?.subscriptionPremium
											?.highlightsPlusInformationSecond && (
											<TextInfo
												dangerouslySetInnerHTML={{
													__html: strings.subscriptionPremium
														.highlightsPlusInformationSecond,
												}}
											></TextInfo>
										)}
								</TextContainer>
							</>
						)}
						<ButtonContainer>
							{alreadyAccess && (
								<>
									<Button
										disabled={errorMessageConfig?.blocking}
										onClick={handleChangePaymentClick}
									>
										Ändra betalsätt
									</Button>
									<Button
										disabled={errorMessageConfig?.blocking}
										onClick={handleChangeAccountClick}
									>
										Köp {productName} med annat konto
									</Button>
								</>
							)}
							<FotbollPlayButton name="fotbollplay" onClick={handleFotbollPlayClick}>
								Tillbaka till FotbollPlay.se
							</FotbollPlayButton>
							<MyPageButton name="my-page" onClick={handleMyPageClick}>
								Fortsätt till Mina Sidor
							</MyPageButton>
						</ButtonContainer>
						<TextContainer>
							{strings?.subscriptionPremium?.rightOfWithdrawal && (
								<TextInfo
									dangerouslySetInnerHTML={{
										__html: strings.subscriptionPremium.rightOfWithdrawal,
									}}
								></TextInfo>
							)}
						</TextContainer>
						<MerchantInfo />
					</>
				)}
			</Content>
		</Container>
	);
}

export default ThankYouPage;
