/**
 * Basic document parts as components.
 */

import styled from 'styled-components';

const headingAttrs = {
	role: 'heading',
};

/**
 * Heading level 1.
 */
export const H1 = styled.h1.attrs(headingAttrs)`
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
`;

/**
 * Heading level 2.
 */
export const H2 = styled.h2.attrs(headingAttrs)`
	color: ${(props) => props.theme.textColor};
	margin: 0;
	padding: 0;
	font-size: 28px;
	line-height: 32px;
	margin-top: 0;
	margin-bottom: 10px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	@media (max-width: 640px) {
		font-size: 20px;
		line-height: 24px;
	}
`;

/**
 * Heading level 3.
 */
export const H3 = styled.h3.attrs(headingAttrs)`
	color: ${(props) => props.theme.textColor};
	margin: 0 0 14px;
	padding: 0;
	font-size: 30px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	@media (max-width: 640px) {
		margin: 0 0 10px;
		font-size: 22px;
		align-self: flex-start;
	}
`;

/**
 * Heading level 4.
 */
export const H4 = styled.h4.attrs(headingAttrs)`
	color: ${(props) => props.theme.textColor};
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	font-size: 22px;
	margin: 0 0 16px;
	@media (max-width: 640px) {
		margin: 0 0 10px;
		font-size: 18px;
		align-self: flex-start;
	}
`;

/**
 * Paragraph.
 */
export const P = styled.p`
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	color: ${(props) => props.theme.textColor};
	font-family: Gilroy-Regular;
	font-weight: normal;
	font-style: normal;
	@media (max-width: 640px) {
		font-size: 13px;
	}
`;
