/**
 * CEE API.
 * http://supplier.sef-platform.info/wiki/Overview_and_Integration_with_CEE
 */

import { makeRequest } from '@utils/http';
import KlarnaOrder from '@klarna/payments/class/KlarnaOrder';
import { CEETypes } from './types.d';
import { CeeKlarnaIntegrationError, CeeKlarnaIntegrationErrorCodes, CeeError } from './error';

const { REACT_APP_CEE_BASE_URL } = process.env;

/**
 * Returna an object with headers for an HTTP request to CEE.
 */
function createRequestHeaders(userToken: string): Record<string, string> {
	return {
		Authorization: `Bearer ${userToken}`,
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};
}

/* OLD API */

/**
 * Creates a new authenticated session.
 * @throws {CeeKlarnaIntegrationError}
 */
export async function createSession(
	userId: string,
	userToken: string,
	order: KlarnaOrder
): Promise<CEETypes.SessionResponseData> {
	const response = await makeRequest(`${REACT_APP_CEE_BASE_URL}/CreateSession/${userId}`, {
		method: 'POST',
		headers: createRequestHeaders(userToken),
		body: JSON.stringify(order.createRequestBody()),
	});

	if (!response.ok) {
		try {
			if (response.headers.get('Content-Type')?.startsWith('application/json')) {
				const ceeError = (await response.json()) as CeeError;
				throw new CeeKlarnaIntegrationError('Unable to create session.', ceeError);
			}
			throw new CeeKlarnaIntegrationError('Unable to create session.', {
				Message: await response.text(),
				Code: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
			});
		} catch (e) {
			if (e instanceof CeeKlarnaIntegrationError) {
				throw e;
			}
			throw new CeeKlarnaIntegrationError('Unable to create session.', {
				Message: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
				Code: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
			});
		}
	}

	return response.json();
}

/**
 * Creates a new order from an authentication token.
 * @throws {CeeKlarnaIntegrationError}
 */
export async function createOrderWithAuthToken(
	userId: string,
	userToken: string,
	order: KlarnaOrder,
	klarnaAuthorizationToken: string,
	klarnaIdempotencyKey: string,
	teamId = '',
	consent = false
): Promise<CEETypes.OrderResponseData> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const body: any = {
		...order.createRequestBody(),
		authorization_token: klarnaAuthorizationToken,
	};

	const optionalQuerystring = teamId
		? `?klarnaIdempotencyKey=${klarnaIdempotencyKey}&consent=${
				consent ? 'true' : 'false'
		  }&teamId=${teamId}`
		: `?klarnaIdempotencyKey=${klarnaIdempotencyKey}`;

	const response = await makeRequest(
		`${REACT_APP_CEE_BASE_URL}/CreateOrderWithAuthToken/${userId}${optionalQuerystring}`,
		{
			method: 'POST',
			headers: createRequestHeaders(userToken),
			body: JSON.stringify(body),
		}
	);

	if (!response.ok) {
		try {
			if (response.headers.get('Content-Type')?.startsWith('application/json')) {
				const ceeError = (await response.json()) as CeeError;
				throw new CeeKlarnaIntegrationError('Unable to create order.', ceeError);
			}
			throw new CeeKlarnaIntegrationError('Unable to create order.', {
				Message: await response.text(),
				Code: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
			});
		} catch (e) {
			if (e instanceof CeeKlarnaIntegrationError) {
				throw e;
			}
			throw new CeeKlarnaIntegrationError('Unable to create order.', {
				Message: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
				Code: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
			});
		}
	}

	return response.json();
}

/**
 * Creates a new order from an authentication token.
 * @throws {CeeKlarnaIntegrationError}
 */
export async function createCustomerTokenWithAuthToken(
	userId: string,
	userToken: string,
	order: KlarnaOrder,
	klarnaAuthorizationToken: string,
	klarnaIdempotencyKey: string,
	teamId = '',
	consent = false
): Promise<CEETypes.OrderResponseData> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const body: any = {
		...order.createRequestBody(),
		authorization_token: klarnaAuthorizationToken,
	};

	const optionalQuerystring = teamId
		? `?klarnaIdempotencyKey=${klarnaIdempotencyKey}&consent=${
				consent ? 'true' : 'false'
		  }&teamId=${teamId}`
		: `?klarnaIdempotencyKey=${klarnaIdempotencyKey}`;

	const response = await makeRequest(
		`${REACT_APP_CEE_BASE_URL}/CreateCustTokenWithAuthToken/${userId}${optionalQuerystring}`,
		{
			method: 'POST',
			headers: createRequestHeaders(userToken),
			body: JSON.stringify(body),
		}
	);

	if (!response.ok) {
		try {
			if (response.headers.get('Content-Type')?.startsWith('application/json')) {
				const ceeError = (await response.json()) as CeeError;
				throw new CeeKlarnaIntegrationError('Unable to create order.', ceeError);
			}
			throw new CeeKlarnaIntegrationError('Unable to create order.', {
				Message: await response.text(),
				Code: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
			});
		} catch (e) {
			if (e instanceof CeeKlarnaIntegrationError) {
				throw e;
			}
			throw new CeeKlarnaIntegrationError('Unable to create order.', {
				Message: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
				Code: CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR,
			});
		}
	}

	return response.json();
}

export async function getUser(userToken: string): Promise<CEETypes.User> {
	const url = `${REACT_APP_CEE_BASE_URL}/api/v2/Authentication/User/CeePersonId`;
	const res = await fetch(url, {
		method: 'GET',
		headers: createRequestHeaders(userToken),
	});

	if (!res.ok) {
		throw new Error('Failed to fetch user from server');
	}

	const user = (await res.json()) as CEETypes.User;
	return user;
}

export async function getPerson(personId: string, userToken: string): Promise<CEETypes.Person> {
	const res = await fetch(`${REACT_APP_CEE_BASE_URL}/api/v2/Persons/${personId}`, {
		method: 'GET',
		headers: createRequestHeaders(userToken),
	});

	if (!res.ok) {
		throw new Error(`Could not get person. ${res.status} - ${res.statusText}`);
	}

	const person = await res.json();
	return person;
}

export async function signOut(userToken: string, userRefreshToken: string): Promise<void> {
	const res = await fetch(
		`${REACT_APP_CEE_BASE_URL}/api/v2/Authentication/User/RefreshToken/${userRefreshToken}`,
		{
			method: 'DELETE',
			headers: createRequestHeaders(userToken),
		}
	);

	if (!res.ok) {
		throw new Error(`Could not sign out. ${res.status} - ${res.statusText}`);
	}
}

export default {
	createRequestHeaders,
	createSession,
	createOrderWithAuthToken,
	createCustomerTokenWithAuthToken,
	getUser,
	getPerson,
	signOut,
};
