import styled from 'styled-components';

export const HuddleImage = styled.div`
	flex-shrink: 0;
	background-image: ${(props: { abbrv: string }) => `url(/g/${props.abbrv}-huddle@2x.jpg)`};
	background-position: center 20%;
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 300px;
	@media (max-width: 640px) {
		height: 152px;
		background-image: ${(props) => `url(/g/${props.abbrv}-huddle.jpg)`};
	}
	@media (min-width: 1280px) {
		width: 60%;
		background-position: center 20%;
		background-image: ${(props) => `url(/g/${props.abbrv}-huddle@3x.jpg)`};
	}
`;

export default HuddleImage;
