import { Logtail } from '@logtail/browser';

let logtail: Logtail | null = null;

export function initializeLogtail() {
	if (process.env.REACT_APP_BETTER_STACK_TOKEN) {
		logtail = new Logtail(process.env.REACT_APP_BETTER_STACK_TOKEN);
	}
}

export function logInfo(message: string, context?: Record<string, any>) {
	if (!logtail) return;

	logtail.info(message, context);
	logtail.flush();
}

export function logError(message: string, context?: Record<string, any>) {
	if (!logtail) return;

	logtail.error(message, context);
	logtail.flush();
}
