/**
 * POPA API.
 */

import { POPATypes } from './types.d';

const { REACT_APP_AUTH_BASE_URL } = process.env;

const CLIENT = 'checkout';
const PLATFORM = 'web';

export function getRedirectToLoginUrl(returnUrl: string): string {
	const frontendCallback = encodeURIComponent(returnUrl);
	return `${REACT_APP_AUTH_BASE_URL}/login?client=${CLIENT}&platform=${PLATFORM}&frontendCallback=${frontendCallback}`;
}

export async function getRedirectToLogoutUrl(idToken: string, returnUrl: string) {
	const frontendCallback = encodeURIComponent(returnUrl);
	const res = await fetch(`${REACT_APP_AUTH_BASE_URL}/logout`, {
		method: 'POST',
		body: JSON.stringify({
			idToken,
			frontendCallback,
		}),
	});

	const data = await res.json();

	if (!res.ok) {
		throw new Error(data.error);
	}

	return data.logoutUrl;
}

export async function getTokensFromTempToken(code: string): Promise<POPATypes.TokensResponseData> {
	const res = await fetch(`${REACT_APP_AUTH_BASE_URL}/tokens`, {
		method: 'POST',
		body: JSON.stringify({
			client: CLIENT,
			platform: PLATFORM,
			code,
		}),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	});

	const data = await res.json();

	if (!res.ok) {
		throw new Error(data.error);
	}

	return data;
}

export default {
	getRedirectToLoginUrl,
	getRedirectToLogoutUrl,
	getTokensFromTempToken,
};
