import React from 'react';
import styled, { css } from 'styled-components';

const CardContainer = styled.div<{
	dark: boolean;
	light: boolean;
	paddingTop: number;
	paddingBottom: number;
	flex: boolean;
}>`
	flex-shrink: 0;
	position: relative;
	/* background-color: #fff; */
	background-color: ${({ theme }) => theme.bgColor};
	padding: 60px 40px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	& h2,
	& h3,
	& h4 {
		// color: #532681;
		color: ${(props) => props.theme.primaryDark};
	}
	@media (max-width: 640px) {
		padding: 30px 20px;
		text-align: left;
	}
	${(props) => css`
		padding-top: ${props.paddingTop}px;
		padding-bottom: ${props.paddingBottom}px;
	`}
	${(props) =>
		props.dark &&
		css`
			background-color: ${props.theme.accentDark};
			color: #a891bf;
			padding: 38px 10px 36px;
			@media (max-width: 640px) {
				padding: 28px 18px 26px;
				text-align: center;
			}
			& h2 {
				color: #fff;
				margin-bottom: 8px;
			}
			& h4 {
				color: #fff;
				align-self: center;
			}
			& p {
				color: #d9c7ea;
			}
		`}
	${(props) =>
		props.light &&
		css`
			background-color: ${props.theme.accentLight};
			color: #32353b;
			padding: 38px 10px 36px;
			& h2 {
				color: #532681;
				// color: props.theme.accent;
			}
			@media (max-width: 640px) {
				padding: 28px 36px 26px;
				text-align: center;
			}
		`}
	${(props) =>
		props.flex &&
		css`
			flex: 0 1 auto;
		`}
`;

const CardContent = styled.div<{ leftAligned: boolean; verticalCenter: boolean }>`
	max-width: 460px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	flex-shrink: 0;
	${(props) =>
		props.leftAligned &&
		css`
			align-items: stretch;
			text-align: left;
		`}
	${(props) =>
		props.verticalCenter &&
		css`
			align-items: stretch;
			justify-content: center;
			flex: 0 1 auto;
		`}
`;

/**
 * Card component.
 * TODO: Describe component.
 */
export default function Card({
	children,
	className = undefined,
	contentMaxWidth,
	dark = false,
	light = false,
	leftAligned = false,
	paddingTop = 0,
	paddingBottom = 18,
	flex = false,
	verticalCenter = false,
}: React.PropsWithChildren<{
	dark?: boolean;
	light?: boolean;
	className?: string;
	contentMaxWidth?: number;
	leftAligned?: boolean;
	paddingTop?: number;
	paddingBottom?: number;
	flex?: boolean;
	verticalCenter?: boolean;
}>): JSX.Element {
	return (
		<CardContainer
			role="article"
			className={`Card${className ? ` ${className}` : ''}`}
			dark={dark}
			light={light}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			flex={flex}
		>
			<CardContent
				leftAligned={leftAligned}
				verticalCenter={verticalCenter}
				style={{ maxWidth: contentMaxWidth }}
			>
				{children}
			</CardContent>
		</CardContainer>
	);
}
