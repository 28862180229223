import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StartPage from './pages/StartPage/StartPage';
import ThankYouPage from './pages/ThankYouPage';
import ChooseTeamPage from './pages/ChooseTeamPage';
import KlarnaPageHighlightPlus from './pages/KlarnaPage/KlarnaPageHighlightPlus';
import TopBar from '@components/TopBar';
import Footer from '@components/Footer';

function StartSubscriptionOldFlow(): JSX.Element {
	return (
		<>
			<TopBar />
			<Routes>
				<Route path="/" element={<StartPage />} />
				<Route path="/choose-team" element={<ChooseTeamPage />} />
				<Route path="/choose-team/:abbrv" element={<ChooseTeamPage />} />
				<Route path="/choose-team/:abbrv/:acceptNewsLetter" element={<ChooseTeamPage />} />
				<Route path="/payment" element={<KlarnaPageHighlightPlus />} />
				<Route
					path="/payment/:abbrv/:acceptNewsLetter"
					element={<KlarnaPageHighlightPlus />}
				/>
				<Route path="/thank-you" element={<ThankYouPage />} />
				<Route path="/thank-you/:orderId" element={<ThankYouPage />} />
				<Route path="/thank-you/:orderId/:abbrv" element={<ThankYouPage />} />
			</Routes>
			<Footer />
		</>
	);
}

export default StartSubscriptionOldFlow;
