/**
 * Klarna Payments API (also called SDK).
 * Documentation: https://developers.klarna.com/documentation/klarna-payments/javascript-sdk/
 */

import CEE from '@api/cee';
import { createScript } from '@utils/dom';
import { Logger } from '@utils/logger';
import config from './config';
import KlarnaOrder from './class/KlarnaOrder';
import KlarnaPaymentsSession from './class/KlarnaPaymentsSession';
import { KlarnaPaymentsTypes } from './types.d';
import { CEETypes } from './../../api/cee/types';

type PaymentMethodCategory = KlarnaPaymentsTypes.PaymentMethodCategory;

const logger = new Logger('Payments');

/**
 * Returns Klarna Payments API object (it exists after script is loaded).
 */
export function getPaymentsAPI(): KlarnaPaymentsTypes.API {
	const API = window.Klarna?.Payments;

	if (!API) {
		throw new Error('Klarna Payments API is not available.');
	}

	return API;
}

/**
 * Creates a simple (bare minimum) order for Klarna requests.
 */
export function createOrderNew(
	name: string,
	price: number,
	taxAmount: number,
	taxRate: number,
	currency: string,
	country: string,
	locale: string,
	reference?: string
): KlarnaOrder {
	const order = new KlarnaOrder(
		currency,
		country,
		locale,
		price * 100,
		taxAmount * 100,
		reference
	);

	return order.addOrderLine({
		name,
		quantity: 1,
		unit_price: price * 100,
		total_amount: price * 100,
		tax_rate: taxRate * 100,
		total_tax_amount: taxAmount * 100,
		type: 'digital',
	});
}

export function createOrder(
	name: string,
	price: number,
	currency: string,
	country: string,
	locale: string,
	teamName: string
): KlarnaOrder {
	const order = new KlarnaOrder(currency, country, locale, price, price * 0.2, teamName);

	return order.addOrderLine({
		name,
		quantity: 1,
		unit_price: Math.floor(price),
		total_amount: Math.floor(price),
		tax_rate: 2500,
		total_tax_amount: Math.floor(price * 0.2),
		type: 'digital',
	});
}

/**
 * Creates a Klarna Payments session.
 */
export async function createSessionNew(
	userId: string,
	userToken: string,
	paymentInfo: CEETypes.CEEOrderPaymentInfo
): Promise<KlarnaPaymentsSession> {
	logger.debug('Create session:', {
		userId,
		userToken,
	});

	// TODO check if split is necessary

	const session = new KlarnaPaymentsSession(
		paymentInfo.Data.SessionId,
		paymentInfo.Data.ClientToken
	);

	paymentInfo.Data.PaymentMethodCategories?.forEach((category) => {
		const klarnaCategory: KlarnaPaymentsTypes.PaymentMethodCategory = {
			identifier: category.Identifier,
			name: category.Name,
			asset_urls: {
				descriptive: category.AssetUrls.Descriptive,
				standard: category.AssetUrls.Standard,
			},
		};
		session.addPaymentMethodCategory(klarnaCategory);
	});

	return session;
}

/**
 * Creates a Klarna Payments session.
 */
export async function createSession(
	userId: string,
	userToken: string,
	order: KlarnaOrder
): Promise<KlarnaPaymentsSession> {
	logger.debug('Create session:', {
		userId,
		userToken,
		order,
	});

	const respData = await CEE.createSession(userId, userToken, order);
	const session = new KlarnaPaymentsSession(respData.session_id, respData.client_token);

	respData.payment_method_categories?.forEach((category) =>
		session.addPaymentMethodCategory(category)
	);

	return session;
}

/**
 * Initializes Klarna API.
 */
export async function init(
	paymentsSession: KlarnaPaymentsSession
): Promise<KlarnaPaymentsTypes.InitResponseData> {
	logger.debug('Init:', {
		paymentsSession,
	});

	if (!paymentsSession) {
		throw new Error('No Payments session.');
	}

	if (window.klarnaAsyncCallback) {
		// API is already initialized.
		return getPaymentsAPI();
	}

	return new Promise((resolve, reject) => {
		// This function is called once when API lib is loaded.
		window.klarnaAsyncCallback = function klarnaAsyncCallback(): void {
			const Payments = getPaymentsAPI();

			logger.debug('Klarna Payments API is loaded:', Payments);

			try {
				Payments.init({
					client_token: paymentsSession.clientToken,
				});
			} catch (err) {
				reject(err);
				return;
			}

			resolve(Payments);
		};

		if (!document.getElementById(config.scriptId)) {
			document.body.appendChild(createScript(config.libSrc, config.scriptId));
		}
	});
}

/**
 * Loads Klarna widget into container element.
 */
export async function loadWidget(
	containerId: string,
	paymentMethodCategory: string
): Promise<KlarnaPaymentsTypes.LoadResponseData> {
	logger.debug('Load widget:', {
		containerId,
		paymentMethodCategory,
	});

	return new Promise((resolve, reject) => {
		const Payments = getPaymentsAPI();

		try {
			Payments.load(
				{
					container: `#${containerId}`,
					payment_method_category: paymentMethodCategory,
				},
				(resp) => {
					logger.debug('Klarna widget is loaded:', resp);

					resolve(resp);
				}
			);
		} catch (err) {
			reject(err);
		}
	});
}

export async function authorize(
	paymentMethodCategory: string,
	order: KlarnaOrder
): Promise<KlarnaPaymentsTypes.AuthorizeResponseData> {
	logger.debug('Authorize:', {
		paymentMethodCategory,
	});

	return new Promise((resolve) => {
		const Payments = getPaymentsAPI();

		try {
			Payments.authorize(
				{
					payment_method_category: paymentMethodCategory,
				},
				order.createRequestBody(),
				(resp) => {
					resolve(resp);
				}
			);
		} catch (err: any) {
			console.log('Error', err);
			resolve(err);
		}
	});
}

export async function finalize(
	paymentMethodCategory: string
): Promise<KlarnaPaymentsTypes.FinalizeResponseData> {
	logger.debug('Finalize:', {
		paymentMethodCategory,
	});

	return new Promise((resolve, reject) => {
		const Payments = getPaymentsAPI();

		try {
			Payments.finalize(
				{
					payment_method_category: paymentMethodCategory,
				},
				(resp) => {
					logger.debug('Finalize response:', resp);
					resolve(resp);
				}
			);
		} catch (err) {
			reject(err);
		}
	});
}

function findPaymentMethodCategory(
	identifier: string,
	paymentsSession: KlarnaPaymentsSession
): PaymentMethodCategory | undefined {
	return paymentsSession.paymentMethodCategories.find(
		(category) => category.identifier === identifier
	);
}

export default {
	getPaymentsAPI,
	createOrderNew,
	createOrder,
	createSessionNew,
	createSession,
	init,
	loadWidget,
	authorize,
	finalize,
	findPaymentMethodCategory,
};
