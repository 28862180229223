import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorPage from '@components/pages/ErrorPage';
import StartPage from './pages/StartPage/StartPage';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage';
import KlarnaPageClubSubscription from './pages/KlarnaPage/KlarnaPageClubSubscription';
import TopBar from '@components/TopBar';
import Footer from '@components/Footer';

function StartClubSubscriptionFlow() {
	return (
		<>
			<TopBar />
			<Routes>
				<Route path="/" element={<StartPage />} />
				<Route path="/payment" element={<KlarnaPageClubSubscription />} />
				<Route path="/thank-you" element={<ThankYouPage />} />
				<Route path="*" element={<ErrorPage status={404} />} />
			</Routes>
			<Footer />
		</>
	);
}

export default StartClubSubscriptionFlow;
