import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { ErrorPageData } from '../types/types.d';
import * as Sentry from '@sentry/react';
import { FLOWS } from '../types/types.d';
import { CEETypes } from '@api/cee/types';
import { Logger } from '@utils/logger';

const logger = new Logger('Redirect');

export default function useRedirect() {
	const { teamName } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const navigateFlow = (flow: FLOWS) => {
		let path = null;
		if (flow === FLOWS.CLUB_SUBSCRIPTION) {
			path = `/${teamName}`;
		} else if (flow === FLOWS.CLUB_PPV) {
			path = `/${teamName}`;
		} else if (flow === FLOWS.SUBSCRIPTION) {
			path = `/`;
		} else if (flow === FLOWS.SUBSCRIPTION_LEGACY) {
			path = `/`;
		} else {
			console.error('Unknown flow!');
		}
		if (path) {
			navigate({ pathname: path, search: searchParams.toString() }, { replace: true });
		}
	};

	const redirectOnError = (errorMessage: string, errorPageData?: ErrorPageData) => {
		logger.error(errorMessage);
		Sentry.captureMessage('useRedirect - ' + errorMessage, 'error');

		navigate(
			{ pathname: '/error', search: searchParams.toString() },
			{ replace: true, state: errorPageData }
		);
	};

	const redirectIfMissingTeam = () => {
		if (!teamName) {
			redirectOnError('Team name in path is missing!');
		}
	};

	const redirectIfMissingProduct = (flow: FLOWS, product: CEETypes.CEEProduct | null) => {
		if (!product) {
			logger.error('Product is missing!');
			navigateFlow(flow);
		}
	};

	const redirectIfMissingProductId = (flow: FLOWS, productId: string | null) => {
		if (!searchParams.get('productid')) {
			redirectOnError('Product id in path is missing!');
		} else if (!productId) {
			navigateFlow(flow);
		}
	};

	const redirectIfAlreadyAccess = (flow: FLOWS, hasAccessToProduct: boolean) => {
		if (hasAccessToProduct) {
			logger.debug('Already access to product, redirecting...');
			if (!searchParams.has('alreadyAccess')) {
				searchParams.append('alreadyAccess', '1');
			}
			let path = null;
			if (flow === FLOWS.CLUB_SUBSCRIPTION) {
				path = `/${teamName}/subscription/thank-you`;
			} else if (flow === FLOWS.CLUB_PPV) {
				path = `/${teamName}/ppv/thank-you`;
			} else if (flow === FLOWS.SUBSCRIPTION) {
				path = `/`;
			} else if (flow === FLOWS.SUBSCRIPTION_LEGACY) {
				path = `/`;
			} else {
				logger.error('Unknown flow!');
			}
			if (path) {
				navigate(
					{
						pathname: path,
						search: searchParams.toString(),
					},
					{
						replace: true,
					}
				);
			}
		}
	};

	return {
		redirectOnError,
		redirectIfMissingTeam,
		redirectIfMissingProduct,
		redirectIfMissingProductId,
		redirectIfAlreadyAccess,
	};
}
