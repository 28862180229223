import { PRODUCTS } from './constants';

const HP = PRODUCTS.HIGHLIGHTS_PLUS;

export default [
	{
		id: 'what-is-fotbollskonto',
		title: 'Vad är ett Fotbollskonto?',
		html: `
			<p>
				Det är gratis att skaffa ett Fotbollskonto och med hjälp
				av det får du tillgång till massa innehåll och en rad
				tjänster. Du kan logga in på
				<a href="${HP.allsvenskanUrl}" target="_blank">Allsvenskans</a> och
				<a href="${HP.superettanUrl}" target="_blank">Superettans</a>
				highlightssajter för att se höjdpunkter från
				ligornas alla matcher. De senaste klippen publiceras
				efter att omgången är färdigspelad. Dessutom kan du
				se dina biljetter i apparna Allsvenskan Live och
				Superettan Live eller i någon av klubbarnas appar, följa
				<a href="${HP.futsalliganUrl}" target="_blank">Svenska Futsalligan</a>
				och mycket mer.
			</p>
		`,
	},
	{
		id: 'who-behind-fotbollskonto',
		title: 'Vem står bakom Fotbollskontot?',
		html: `
			<p>
				Det gör Föreningen Svensk Elitfotboll (SEF), som är en
				gemensam intresseförening för de 32 klubbar som spelar
				i Allsvenskan och Superettan med ansvar att driva
				utveckling av herrelitfotbollen. Svensk Elitfotboll
				har utvecklat Fotbollskontot och tjänster som
				Highlights Plus. Läs gärna mer på
				<a href="${HP.sefUrl}" target="_blank">svenskelitfotboll.se</a>.
			</p>
		`,
	},
	{
		id: 'what-is-highlights-plus',
		title: 'Vad är Highlights Plus?',
		html: `
			<p>
				Genom tjänsten Highlights Plus kan du se de viktigaste
				höjdpunkterna från alla matcher i Allsvenskan och Superettan
				direkt under matchen. Klippen publiceras med bara några sekunders
				fördröjning på
				<a href="${HP.allsvenskanUrl}" target="_blank">Allsvenskans</a> och
				<a href="${HP.superettanUrl}" target="_blank">Superettans</a> highlightssajter.
				Snart kommer de även att bli tillgängliga i i våra appar
				(se länkar nedan) där du även kommer kunna slå på pushnotiser
				så att du aldrig missar ett mål.
			</p>
			<p>
				Abonnemanget för Highlights Plus kostar ${HP.monthlyCost}
				kr/månad och är utan bindningstid. Vi rekommenderar att läsa igenom våra
				<a href="${HP.specialTermsUrl}" target="_blank">villkor för Highlights Plus</a>.
			</p>
			<p>
				Ladda ned och logga in med ditt Fotbollskonto i Allsvenskans
				eller Superettans app eller i någon av klubbarnas appar.
				Sök på klubbens namn och "live" i App Store eller Google Play
				så hittar du dem. (Alltså till exempel “Sirius Live”.)
			</p>
			<p>
				<a href="${HP.allsvenskanAppStoreUrl}" target="_blank">Allsvenskan Live | App Store</a>
				<br />
				<a href="${HP.allsvenskanGooglePlayUrl}" target="_blank">Allsvenskan Live | Google Play</a>
				<br />
				<a href="${HP.superettanAppStoreUrl}" target="_blank">Superettan Live | App Store</a>
				<br />
				<a href="${HP.superettanGooglePlayUrl}" target="_blank">Superettan Live | Google Play</a>
			</p>

			<h3>Vad kostar Highlights Plus?</h3>
			<p>
				Highlights Plus kostar ${HP.monthlyCost} kronor i månaden
				och abonnemanget är utan bindningstid. När abonnemanget är igång så
				ser du att pengarna dras varje månad och i
				<a href="${HP.fotbollskontoUrl}" target="_blank">ditt Fotbollskonto</a>
				kan du se din betalningshistorik. Med hjälp av
				<a href="${HP.klarnaLoginUrl}" target="_blank">Klarnas hemsida</a>
				eller app kan du se lite mer detaljer om du skulle behöva ett kvitto.
				<a href="${HP.checkoutUrl}" target="_blank">Här kan du enkelt teckna ett abonnemang</a>
				och betala antingen med kort eller bankkonto via Klarna.
			</p>

			<h3>Är det någon bindningstid för Highlights Plus?</h3>
			<p>
				Nej. Du kan närsomhelst säga upp ditt abonnemang. Tjänsten fortsätter
				då att vara tillgänglig för den perioden du betalt för och sedan
				upphör månadsbetalningarna.
			</p>

			<h3>Vart går pengarna?</h3>
			<p>
				Till den klubb du vill! Överskottet från din månadsavgift går till
				klubbarna. Du kan välja en klubb som du önskar ska få ditt stöd,
				annars kommer pengarna fördelas mellan alla klubbar i Allsvenskan
				och Superettan om du inte gör något aktivt val.
			</p>
		`,
	},
	{
		id: 'where-video-clips-matches',
		title: 'Var kan jag se videoklipp från matcher?',
		html: `
			<p>
				Med ett Fotbollskonto kan du kan se alla viktiga
				matchhändelser på highlightssajterna för
				<a href="${HP.allsvenskanUrl}" target="_blank">Allsvenskan</a>,
				<a href="${HP.superettanUrl}" target="_blank">Superettan</a> och
				<a href="${HP.futsalliganUrl}" target="_blank">Futsalligan</a>
				och i våra appar. Alla ligornas
				30 omgångar samlar vi där, och det finns klipp från 2017
				och framåt. Klippen läggs upp klockan 18 dagen efter att
				omgången är slutspelad. Har du även tjänsten Highlights
				Plus kan du se höjdpunkterna direkt under matchen!
			</p>
		`,
	},
	{
		id: 'how-to-cancel-account',
		title: 'Hur gör jag för att avsluta mitt konto eller abonnemang?',
		html: `
			<p>
				Om du vill avsluta
				<a href="${HP.fotbollskontoUrl}" target="_blank">ditt Fotbollskonto</a>
				eller radera/få del av den
				information vi har sparad om dig, maila till
				<a href="mailto:${HP.sefHelpEmail}" target="_blank">${HP.sefHelpEmail}</a>
				så hjälper vi dig.
			</p>
			<p>
				Om du vill avsluta ditt abonnemang på Highlights Plus gör du det
				enkelt i ditt Fotbollskonto. Efter att du avslutat abonnemanget
				kan du fortsätta använda tjänsten till och med det datum du redan
				betalat för.
			</p>
		`,
	},
	{
		id: 'how-personal-data-treated',
		title: 'Hur behandlas mina personuppgifter?',
		html: `
			<p>
				Vi gör vårt yttersta för att dina personuppgifter ska vara
				säkra hos oss. Mer detaljer kring persondata hittar du i vår
				<a href="${HP.integrityPolicyUrl}" target="_blank">integritetspolicy</a>.
			</p>
		`,
	},
	{
		id: 'where-other-questions',
		title: 'Om jag har andra frågor, vart kan jag vända mig?',
		html: `
			<p>
				Om du har frågor eller tankar angående ditt Fotbollskonto eller
				våra tjänster får du gärna maila oss på
				<a href="mailto:support@svenskelitfotboll.se" target="_blank">support@svenskelitfotboll.se</a>.
				Vi rekommenderar annars alltid att läsa igenom våra
				<a href="${HP.commonTermsUrl}" target="_blank">Allmänna villkor</a>.
			</p>
		`,
	},
];
