import styled from 'styled-components';

export const ContentP = styled.p`
	margin: 0 0 20px;
	padding: 0;
	font-size: 14px;
	color: #32353b;
	padding: 0 40px 20px;
	text-align: center;
	font-family: Gilroy-Regular;
	font-weight: normal;
	font-style: normal;
	line-height: 20px;
	@media (max-width: 640px) {
		padding: 0 10px;
		font-size: 13px;
	}
`;

export const ColumnFieldset = styled.fieldset`
	border: none;
	text-align: left;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	padding: 0;
`;

export const Fieldset = styled.fieldset`
	border: none;
	text-align: left;
	margin-bottom: 6px;
	padding: 0;
	display: flex;
`;

export const PrimaryLabel = styled.label`
	font-size: 14px;
	color: #532681;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	margin-bottom: 9px;
	@media (max-width: 640px) {
		font-size: 13px;
		margin-bottom: 7px;
	}
`;

export const Label = styled.label`
	color: #32353b;
	font-size: 13px;
	display: block;
	font-family: Gilroy-Regular;
	font-weight: normal;
	font-style: normal;
	line-height: 18px;
	margin-left: 10px;
	@media (max-width: 640px) {
		font-size: 12px;
	}
`;

export const Select = styled.select`
	padding: 10px;
	align-self: stretch;
	margin-bottom: 16px;
`;
