import React, { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export default React.memo(function AppGTMStart() {
	const sendDataToGTM = useGTMDispatch();

	useEffect(() => {
		sendDataToGTM({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});
	}, [sendDataToGTM]);

	return null;
});
