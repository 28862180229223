import React from 'react';
import Container from '@components/Container';
import Card from '@components/Card';
import { H3, P } from '@components/Parts';

/**
 * Fallback component shown when Page render fails
 */
export default function Fallback() {
	return (
		<Container role="document" className="Fallback">
			<Card contentMaxWidth={769} paddingTop={20}>
				<H3>Självmål!</H3>

				<P>
					Ett oväntat fel inträffade. Vi har sparat information om problemet och kommer
					att försöka lösa det så fort som möjligt. Försök igen lite senare.
				</P>
			</Card>
		</Container>
	);
}
