import React, { useState, useEffect, useRef } from 'react';
import { useApp } from '@contexts/app';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Container from '@components/Container';
import Card from '@components/Card';
import Button from '@components/Button';
import { H3 as PartH3, P } from '@components/Parts';
import useIsSubscriber from '@hooks/useIsSubscriber';
import AlreadySubscribedDialog from '../../components/AlreadySubscribedDialog';
import AccountFAQInfo from '@components/AccountFaqInfo';
import { PRODUCTS } from '@utils/constants';
import { useProduct } from '@contexts/product';
import { CEETypes } from '@api/cee/types';
import { HIGHLIGHTS_PLUS_ID } from '@data/constants';
import ErrorMessage from '@components/ErrorMessage';
import StartImage from './StartImage';
import { Logger } from '@utils/logger';

const logger = new Logger('StartPage - H+ Subscription legacy');

const { REACT_APP_FOTBOLL_PLAY_URL } = process.env as Record<string, string>;

const TEXT_CONTENT = `
	Ibland kommer livet emellan och hur mycket man än vill se hela matchen så måste man ibland göra något annat.
	Ett abonnemang på Highlights Plus passar för dig som inte kan se bortamatchen live, har svårt att hinna följa alla matcher på tv,
	vill se straffsituationen igen eller som vill följa flera lag samtidigt på ett smidigt och enkelt sätt!<br />
	<br />
	Höjdpunkterna finns tillgängliga via <a href="${REACT_APP_FOTBOLL_PLAY_URL}" target="_blank">Fotbollplay.se</a> eller i våra appar. Där kan du även slå på pushnotiser för matchhändelser så att du inte missar något mål.
`;

const ContentP = styled(P)`
	margin-top: 28px;
	margin-bottom: 28px;
	@media (max-width: 640px) {
		margin-top: 24px;
		margin-bottom: 24px;
	}
`;

const ButtonSeparator = styled.div`
	flex-shrink: 0;
	height: 18px;
	@media (max-width: 640px) {
		height: 12px;
	}
`;

const Bullets = styled.ul`
	padding: 20px 47px 20px 60px;
	background-color: #faf9fc;
	border: 1px solid #f6f4f9;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	font-size: 14px;
	text-align: left;
	// color: #341058;
	color: ${(props) => props.theme.accent};
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 38px;
	& li::marker {
		font-size: 18px;
	}
	@media (max-width: 640px) {
		padding: 18px 12px 20px 37px;
		margin-left: -20px;
		margin-right: -20px;
		font-size: 13px;
	}
`;

const getHasAccessToHighlightsPlus = (products?: Record<string, CEETypes.SubscriptionStatus>) => {
	const { HasAccessUntil } = (products && products[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION]) || {};
	return HasAccessUntil ? new Date(HasAccessUntil) > new Date() : false;
};

export const H3 = styled(PartH3)`
	color: ${(props) => props.theme.accent};
`;

function StartPage(): JSX.Element {
	const navigate = useNavigate();
	const { errorMessageConfig } = useApp();
	const { abbrv } = useParams<{
		abbrv: string;
	}>();
	const { tokens, products } = useApp();
	const { productId, setProductId } = useProduct();
	const isSubscriber = useIsSubscriber();
	const [alreadySubscribedDialogOpen, setAlreadySubscribedDialogOpen] = useState(false);
	const loadedOnce = useRef(false);

	useEffect(() => {
		if (
			!loadedOnce.current &&
			(isSubscriber || getHasAccessToHighlightsPlus(products)) &&
			products?.[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION].Status !== 'Cancelled'
		) {
			loadedOnce.current = true;
			setAlreadySubscribedDialogOpen(true);
		}
	}, [isSubscriber, products, setProductId, productId]);

	useEffect(() => {
		if (!productId) {
			setProductId(HIGHLIGHTS_PLUS_ID);
		}
	}, [productId, setProductId]);

	return (
		<>
			<Container role="document" className="StartPage">
				<ErrorMessage />
				<Card contentMaxWidth={769} paddingTop={40} flex>
					<StartImage />
					{!tokens?.accessToken && (
						<Button
							callToAction
							disabled={errorMessageConfig?.blocking}
							name="redirect-to-register"
							action={() =>
								navigate(`/subscription-legacy/choose-team/${abbrv || ''}`, {
									replace: true,
								})
							}
						>
							Skapa konto
						</Button>
					)}
					<ButtonSeparator />
					{tokens?.accessToken && (
						<Button
							callToAction
							disabled={errorMessageConfig?.blocking}
							name="redirect-to-login"
							action={() =>
								navigate(`/subscription-legacy/choose-team/${abbrv || ''}`, {
									replace: true,
								})
							}
						>
							Starta abonnemang
						</Button>
					)}
					{!tokens?.accessToken && (
						<Button
							disabled={errorMessageConfig?.blocking}
							name="redirect-to-login"
							action={() =>
								navigate(`/subscription-legacy/choose-team/${abbrv || ''}`, {
									replace: true,
								})
							}
						>
							Logga in
						</Button>
					)}
					<ContentP dangerouslySetInnerHTML={{ __html: TEXT_CONTENT }} />
					<Bullets>
						<li>Höjdpunkter från Allsvenskans och Superettans 30 omgångar</li>
						<li>Pushnotiser direkt i app</li>
						<li>För en månadsavgift på 35 kr</li>
						<li>Utan bindningstid</li>
						<li>Överskottet från ditt abonnemang kan stötta din favoritklubb</li>
					</Bullets>
					<AccountFAQInfo showUseSameAccount />
				</Card>

				{alreadySubscribedDialogOpen && (
					<AlreadySubscribedDialog
						abbrv={abbrv}
						onClose={() => setAlreadySubscribedDialogOpen(false)}
					/>
				)}
			</Container>
		</>
	);
}

export default StartPage;
