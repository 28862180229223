import { POPATypes } from '@api/popa/types';
import { CEETypes } from '@api/cee/types';

export enum FLOWS {
	SUBSCRIPTION = 'subscription',
	SUBSCRIPTION_LEGACY = 'subscription-legacy',
	CLUB_PPV = 'ppv',
	CLUB_SUBSCRIPTION = 'clubSubscription',
}

export type TokenData = POPATypes.TokensResponseData & CEETypes.User;

export type ErrorPageData = {
	title?: string;
	subtitle?: string;
	message?: string;
};

export interface AlertError {
	severity?: 'info' | 'warning' | 'error'; // TODO Handle severity
	title: string;
	text?: string;
	ref?: string;
}

export namespace ProductTypes {
	export interface PricePlan {
		Id: string;
		Etag: string;
		Name: string;
		BillingPeriodType: string;
		PriceCurrency: string;
		PriceAmount: number;
		VatRatePercent: number;
	}
	export interface Product {
		Id: string;
		Etag: string;
		BillingStatus: string;
		BillingType: string;
		Name: string;
		DisplayName: string;
		Description?: string;
		Category: string;
		Channel: string;
		MerchantId: string;
		PricePlans: PricePlan[];
		ExternalId: {
			Source: string;
			Id: string;
		};
		PaymentRequiredUntil?: string;
	}
}
