import React from 'react';
import styled from 'styled-components';
import { useApp } from '@contexts/app';
import { P } from '@components/Parts';

const Container = styled.div`
	text-align: center;
	max-width: 400px;
	align-self: center;
	width: 100%;
	padding-bottom: 18px;
`;

const TopLine = styled.div`
	margin-top: 35px;
	background-color: #f6f4f9;
	height: 2px;
	width: 100%;
	margin-bottom: 30px;
	@media (max-width: 640px) {
		margin-top: 30px;
		margin-bottom: 20px;
	}
`;

const Footer = styled(P)`
	margin: 0 0 4px;
	font-size: 13px;
	color: #656565;
	@media (max-width: 640px) {
		margin: 0;
		font-size: 12px;
	}
`;

const FaqLink = styled.a`
	text-decoration: underline;
	cursor: pointer;
	fontfamily: 'Gilroy-Bold';
	font-size: 13px;
	@media (max-width: 640px) {
		font-size: 12px;
	}
`;

export default function AccountFAQInfo({
	showUseSameAccount = false,
}: React.PropsWithChildren<{ showUseSameAccount?: boolean }>): JSX.Element {
	const { showFAQ } = useApp();

	return (
		<Container>
			<TopLine />

			{showUseSameAccount && (
				<Footer>Använd samma konto på Svensk Elitfotbolls tjänster.</Footer>
			)}

			<FaqLink onClick={showFAQ}>Har du frågor? Läs mer i vår FAQ</FaqLink>
		</Container>
	);
}
