import * as React from 'react';
import ReactSelect, {
	Props,
	Theme,
	StylesConfig,
	OptionProps,
	components,
	ControlProps,
} from 'react-select';
import styled, { ThemeContext } from 'styled-components';

const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	> * {
		:first-child {
			margin-right: 8px;
		}
	}
`;

export type Option = { label: string; value: string; icon?: React.ReactNode };
const OptionWithIcon = (props: OptionProps<Option>) => {
	const { label, icon } = props.data;
	return (
		<components.Option {...props}>
			<OptionContainer>
				{icon}
				<span>{label}</span>
			</OptionContainer>
		</components.Option>
	);
};

const ControlWithIcon = (props: ControlProps<Option>) => {
	const { children, ...rest } = props;
	const icon = props.hasValue && !props.isMulti && props.getValue()[0]?.icon;
	return (
		<components.Control {...rest}>
			<span style={{ marginLeft: 8 }}>{icon}</span>
			{children}
		</components.Control>
	);
};

export type SelectProps = Props<Option>;
function Select(props: SelectProps) {
	const appTheme = React.useContext(ThemeContext);

	const overridenTheme = (theme: Theme): Theme => ({
		...theme,
		spacing: {
			...theme.spacing,
			controlHeight: 42,
			menuGutter: 1,
		},
		colors: {
			...theme.colors,
			primary: appTheme.selectOption.selected, // selected option
			primary25: appTheme.selectOption.hover, // hover option
			neutral80: appTheme.fields.text, // selected text
		},
	});

	const styles: StylesConfig<Option> = {
		control: (base, state) => ({
			...base,
			boxShadow: 'none',
			fontSize: 12,
			fontWeight: 'bold',
			minWidth: 160,
			borderColor: appTheme.fields.border,
			backgroundColor: state.hasValue ? appTheme.fields.background : 'transparent',
			':hover': {
				backgroundColor: 'transparent',
				borderColor: appTheme.accent,
			},
			':focus-within': {
				backgroundColor: 'transparent',
				borderWidth: 2,
				borderColor: appTheme.accent,
			},
		}),
		option: (base) => ({
			...base,
			fontSize: 13,
			transition: `all ${appTheme.transitionTiming}`,
			color: 'inherit',
			':active': {
				backgroundColor: appTheme.selectOption.backgroundActive,
			},
		}),
	};

	return (
		<ReactSelect
			{...props}
			theme={overridenTheme}
			styles={styles}
			components={{
				IndicatorSeparator: null,
				Option: OptionWithIcon,
				Control: ControlWithIcon,
			}}
		/>
	);
}

export default Select;
