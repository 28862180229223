import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from '@components/Container';
import Card from '@components/Card';
import Footer from '@components/Footer';
import CtaButton from '@components/CtaButton';
import { H1 as H1Part, H2 as H2Part, P } from '@components/Parts';
import { ErrorPageData } from '../../types/types.d';
import { useProduct } from '@contexts/product';

const { REACT_APP_FOTBOLL_PLAY_URL } = process.env;

const H1 = styled(H1Part)`
	color: #341058;
	font-size: 62px;
	margin-bottom: 0;
`;

const H2 = styled(H2Part)`
	color: #341058;
	font-size: 20px;
	margin-top: 0;
`;

/**
 * Page component that's shown when a serious error occurs.
 */
export default function ErrorPage({
	status = 0,
}: React.PropsWithChildren<{ status?: number }>): JSX.Element {
	const location = useLocation();
	const { redirectUrl } = useProduct();
	let displayError = location.state as ErrorPageData;

	switch (status) {
		case 404:
			displayError = {
				title: '404',
				subtitle: 'Sidan kunde inte hittas',
				message: '',
			};
			break;
		default:
			break;
	}

	function handleRedirect(): void {
		window.location.href = redirectUrl || (REACT_APP_FOTBOLL_PLAY_URL as string);
	}

	return (
		<>
			<Container role="document">
				<Card contentMaxWidth={450} flex verticalCenter>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							marginBottom: 50,
						}}
					>
						<H1>{displayError?.title || 'Ojdå'}</H1>
						<H2>{displayError?.subtitle || 'Tyvärr har ett oväntat fel uppstått'}</H2>
						{/* <P>{displayError?.message || 'Var god försök igen senare.'}</P> */}
						<div style={{ marginTop: 20 }} />
						<CtaButton name="navigate" onClick={handleRedirect}>
							Gå tillbaka
						</CtaButton>
					</div>
				</Card>
			</Container>
			<Footer />
		</>
	);
}
