import React, { useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useBranding } from './branding';
import {
	ColorScheme,
	defaultPalette,
	disabledColors,
	dividerColors,
	insertColors,
	Palette,
} from '../style';

export const AppThemeContext = React.createContext<any | null>(null);

const AppThemeProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
	const { palette: clubPalette } = useBranding();

	const selectedColorScheme: ColorScheme = 'light';

	if (clubPalette) {
		// TEMP Disabled dark themes and overriding colors
		// selectedColorScheme = clubPalette.lightMode ? 'light' : 'dark';
		clubPalette.textColor = defaultPalette.textColor;
		clubPalette.bgColor = defaultPalette.bgColor;
	}

	const theme: Palette = {
		...defaultPalette,
		...(clubPalette ? clubPalette : {}),
		cta: clubPalette ? clubPalette.cta || clubPalette.primary : defaultPalette.cta,
		topbarBorder: clubPalette
			? clubPalette.topbarBorder || clubPalette.accent
			: defaultPalette.topbarBorder,
		divider: dividerColors[selectedColorScheme],
		insertBackground: insertColors[selectedColorScheme],
		disabledBackground: disabledColors[selectedColorScheme],
	};

	return (
		<AppThemeContext.Provider value={{}}>
			<StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
		</AppThemeContext.Provider>
	);
};

export const useAppTheme = () => useContext(AppThemeContext);

export default AppThemeProvider;
