import React from 'react';
import styled from 'styled-components';
import { H4 as PartH4, P } from '@components/Parts';
import Card from '@components/Card';
import Spinner from '@components/Spinner';

const H4 = styled(PartH4)`
	margin-top: 16px;
	margin-bottom: 8px;
	@media (max-width: 640px) {
		margin-top: 10px;
		margin-bottom: 6px;
		align-self: center;
	}
`;

interface Props {
	text: string;
}

function LoadingCard({ text }: Props) {
	return (
		<Card>
			<Spinner />
			<H4>{text}</H4>
			<P>Det kan ta några sekunder</P>
		</Card>
	);
}

export default LoadingCard;
