import React, { useContext, useState, useEffect, useMemo } from 'react';
import CEE from '@api/cee/ceeV3';
import { useApp } from '@contexts/app';
import { CEETypes } from '@api/cee/types';
import { HIGHLIGHTS_PLUS_ID } from '@data/constants';
import { Logger } from '@utils/logger';

const logger = new Logger('ProductContext');

interface ProductContextProps {
	productId: string | null;
	setProductId: (id: string) => void;
	product: CEETypes.CEEProduct | null;
	loading: boolean;
	error: boolean;
	redirectUrl: string | null;
	hasAccessToProduct: boolean;
	setRedirectUrl: (url: string) => void;
}

const highlightPlusProduct: CEETypes.CEEProduct = {
	Id: 'highlightsplus',
	BillingStatus: 'ForSale',
	BillingType: 'Subscription',
	Name: 'Highlights Plus',
	DisplayName: 'Highlights Plus',
	Description: 'Highlight Plus',
	Category: 'category',
	Channel: 'channel',
	MerchantId: 'highlightsplus',
	PricePlans: [
		{
			Id: 'highlightsplus',
			Name: 'HighlightPlus',
			PriceCurrency: 'SEK',
			PriceAmount: 35,
			VatRatePercent: 25,
		},
	],
};

const fetchProduct = async (productId: string, accessToken: string) => {
	if (productId === HIGHLIGHTS_PLUS_ID) {
		return highlightPlusProduct;
	} else {
		return CEE.fetchProduct(accessToken, productId);
	}
};

export const ProductContext = React.createContext<ProductContextProps>({
	productId: null,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setProductId: (id) => {},
	product: null,
	loading: false,
	error: false,
	redirectUrl: null,
	hasAccessToProduct: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setRedirectUrl: (url) => {},
});

export const useProduct = () => useContext(ProductContext);

const ProductProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
	const { tokens } = useApp();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [product, setProduct] = useState<CEETypes.CEEProduct | null>(null);
	const [productId, setProductId] = useState<string | null>(null);
	const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
	const { products } = useApp();

	useEffect(() => {
		const getProduct = async () => {
			if (productId && tokens?.accessToken) {
				setLoading(true);
				setError(false);
				try {
					const fetchedProduct = await fetchProduct(productId, tokens.accessToken);
					setProduct(fetchedProduct);
					logger.debug('Fetched product', fetchedProduct);
				} catch (e) {
					console.error(e);
					setError(true);
				} finally {
					setLoading(false);
				}
			}
		};
		getProduct();
	}, [productId, tokens]);

	const hasAccessToProduct = useMemo(() => {
		if (!productId || !products) {
			return false;
		}
		const subscription = products[productId.toUpperCase()];
		return subscription?.Status === 'Active' || false;
	}, [productId, products]);

	return (
		<ProductContext.Provider
			value={{
				setProductId,
				productId,
				product,
				loading,
				error,
				redirectUrl,
				setRedirectUrl,
				hasAccessToProduct,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

export default ProductProvider;
