import React from 'react';
import styled from 'styled-components';

export const StartImageStyled = styled.img`
	background-image: url('/g/checkout-as.jpg');
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	height: auto;
	margin-bottom: 30px;
	@media (max-width: 640px) {
		margin-bottom: 20px;
	}
`;

export default function StartImage(): JSX.Element {
	return <StartImageStyled width="1920" height="1080" />;
}
