/* eslint-disable no-console */

/**
 * Log levels:
 * - DEBUG: Log everything.
 * - INFO: Log everything except debug.
 * - WARN: Log warnings and errors.
 * - ERROR: Log errors only.
 */
export const LEVELS = {
	DEBUG: 0,
	INFO: 1,
	WARN: 2,
	ERROR: 3,
	DEFAULT: process.env.NODE_ENV !== 'production' ? 0 : 2,
};

/**
 * CSS styles for console output.
 */
export const STYLES = {
	DEBUG: 'color:lime',
	INFO: 'color:aqua',
	WARN: 'color:gold',
	ERROR: 'color:tomato',
	DEFAULT: 'color:auto',
};

/**
 * Logger class for logging (obviously).
 */
export class Logger {
	name: string;

	level: number;

	styling: boolean;

	constructor(
		name: string,
		level: number = LEVELS.DEFAULT,
		styling = process.env.NODE_ENV === 'development'
	) {
		this.name = name;
		this.level = level;
		this.styling = styling;
	}

	getPrefix(type?: string): string {
		const nameExt = type ? `:${type}` : '';
		return `[${this.name}${nameExt}]`;
	}

	/**
	 * Logs without type at any level.
	 */
	log(...entries: unknown[]): Logger {
		const prefix = this.getPrefix();

		if (this.styling) {
			console.log(`%c${prefix}`, STYLES.DEFAULT, ...entries);
		} else {
			console.log(prefix, ...entries);
		}

		return this;
	}

	/**
	 * Logs as INFO type at any level except DEBUG.
	 */
	info(...entries: unknown[]): Logger {
		if (this.level <= LEVELS.INFO) {
			const prefix = this.getPrefix('INFO');

			if (this.styling) {
				console.info(`%c${prefix}`, STYLES.INFO, ...entries);
			} else {
				console.info(this.getPrefix('INFO'), ...entries);
			}
		}

		return this;
	}

	/**
	 * Logs as WARN type at WARN and ERROR level.
	 */
	warn(...entries: unknown[]): Logger {
		if (this.level <= LEVELS.WARN) {
			const prefix = this.getPrefix('WARN');

			if (this.styling) {
				console.warn(`%c${prefix}`, STYLES.WARN, ...entries);
			} else {
				console.warn(prefix, ...entries);
			}
		}

		return this;
	}

	/**
	 * Logs as ERROR type at ERROR level.
	 */
	error(...entries: unknown[]): Logger {
		if (this.level <= LEVELS.ERROR) {
			const prefix = this.getPrefix('ERROR');

			if (this.styling) {
				console.error(`%c${prefix}`, STYLES.ERROR, ...entries);
			} else {
				console.error(prefix, ...entries);
			}
		}

		return this;
	}

	/**
	 * Logs as DEBUG type at any level.
	 */
	debug(...entries: unknown[]): Logger {
		if (this.level <= LEVELS.DEBUG) {
			const prefix = this.getPrefix('DEBUG');

			if (this.styling) {
				console.debug(`%c${prefix}`, STYLES.DEBUG, ...entries);
			} else {
				console.debug(prefix, ...entries);
			}
		}

		return this;
	}
}
