import { ExtendableError } from 'ts-error';

export interface NewCeeErrorDetails {
	ErrorCode?: string;
	ValidationError?: string;
	PaymentProviderError?: string;
	AssertionError?: string;
}

export interface NewCeeError {
	Message: string;
	Type: string;
	HttpStatusCode: string;
	ReasonPhrase: string;
	CorrelationId: string;
	Details?: NewCeeErrorDetails;
}

export interface CeeIntegrationError {
	message?: string;
	ceeError?: NewCeeError;
}

export class CeeNewKlarnaIntegrationError extends ExtendableError {
	private ceeIntegrationError?: CeeIntegrationError;

	constructor(message: string, ceeIntegrationError?: CeeIntegrationError) {
		super(message);
		this.ceeIntegrationError = ceeIntegrationError;
	}

	public getCeeIntegrationError(): CeeIntegrationError | undefined {
		return this.ceeIntegrationError;
	}
}

export enum CeeKlarnaIntegrationErrorCodes {
	// Create order errors
	UNSPECIFIED_ERROR = 'UNSPECIFIED_ERROR',
	// Internal error codes
	// Errors that are a result of bad data in CEE
	INVALID_BILLING_PERIOD = 'INVALID_BILLING_PERIOD',
	CUSTOMER_TOKEN_IS_MISSING = 'CUSTOMER_TOKEN_IS_MISSING',
	SUBSCRIPTION_IS_MISSING = 'SUBSCRIPTION_IS_MISSING',
	CUSTOMER_TOKEN_ORDER_IS_MISSING = 'CUSTOMER_TOKEN_ORDER_IS_MISSING',
	SUBSCRIPTION_IS_MISSING_END_DATE = 'SUBSCRIPTION_IS_MISSING_END_DATE',
	DUPLICATE_SUBSCRIPTION_PERIODS_FOUND = 'DUPLICATE_SUBSCRIPTION_PERIODS_FOUND',
	DUPLICATE_PERSONS_FOUND = 'DUPLICATE_PERSONS_FOUND',
	// External error codes
	// Errors that are a result of subscription status etc.
	INVALID_ORDER_LINES = 'INVALID_ORDER_LINES',
	SUBSCRIPTION_IS_NOT_CANCELLED = 'SUBSCRIPTION_IS_NOT_CANCELLED',
	SUBSCRIPTION_IS_NOT_ACTIVE = 'SUBSCRIPTION_IS_NOT_ACTIVE',
	SUBSCRIPTION_IS_NOT_NEW_OR_CANCELLED = 'SUBSCRIPTION_IS_NOT_NEW_OR_CANCELLED',
	TOO_EARLY_FOR_NEW_ORDER = 'TOO_EARLY_FOR_NEW_ORDER',
	ORDER_NOT_ACCEPTED = 'ORDER_NOT_ACCEPTED',
	ORDER_ID_IS_MISSING = 'ORDER_ID_IS_MISSING',
}

export interface CeeError {
	Message: string;
	Code: CeeKlarnaIntegrationErrorCodes;
}
export class CeeKlarnaIntegrationError extends ExtendableError {
	private ceeError: CeeError;

	constructor(message: string, ceeError: CeeError) {
		super(message);
		this.ceeError = ceeError;
	}

	public getCeeError(): CeeError {
		return this.ceeError;
	}
}
