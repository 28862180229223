import React from 'react';
import styled from 'styled-components';
import Container from '@components/Container';
import Card from '@components/Card';
import Button from '@components/Button';
import { H2, P } from '@components/Parts';
import { useParams } from 'react-router-dom';
import { useApp } from '@contexts/app';
import HuddleImage from './HuddleImage';
import { useProduct } from '@contexts/product';
import AppLinks from '@components/AppLinks';

// Temporary abbrv until we support different images
const HUDDLE_IMAGE_ABBRV = 'huddle';

const {
	REACT_APP_SUPERETTAN_HIGHLIGHTS_URL,
	REACT_APP_ALLSVENSKAN_HIGHLIGHTS_URL,
	REACT_APP_MY_PAGE_URL,
} = process.env;

const Email = styled.a`
	color: ${(props) => props.theme.accent};
`;

const Header = styled(H2)`
	color: ${(props) => props.theme.accent};
	text-align: center;
`;

const Text = styled(P)`
	margin-top: 2px;
	margin-bottom: 0;
	text-align: center;
`;

export const Box = styled.div`
	display: flex;
	justify-content: center;
	align-self: stretch;
	flex-direction: column;
	margin-bottom: 16px;
	@media (max-width: 732px) {
		flex-direction: column;
	}
`;

export const Spacing = styled.div`
	height: 20px;
`;

function buttonAction(event: React.MouseEvent, buttonName: string): void {
	let url;

	switch (buttonName) {
		case 'allsvenskan-highlights':
			url = `${REACT_APP_ALLSVENSKAN_HIGHLIGHTS_URL}?rs=1`;
			break;
		case 'superettan-highlights':
			url = `${REACT_APP_SUPERETTAN_HIGHLIGHTS_URL}?rs=1`;
			break;
		case 'my-page':
			url = REACT_APP_MY_PAGE_URL;
			break;
		default:
			break;
	}

	if (url) {
		window.location.href = url;
	}
}

function ThankYouPage(): JSX.Element {
	const { abbrv = '' } = useParams<{
		abbrv: string;
	}>();
	const { person } = useApp();
	const { productId } = useProduct();

	if (!productId) {
		throw new Error('ThankYouPage - Product is missing!');
	}

	return (
		<Container role="document" className="ThankYouPage">
			<HuddleImage aria-label="Tack!" abbrv={HUDDLE_IMAGE_ABBRV} />
			<Card contentMaxWidth={632} paddingTop={60} paddingBottom={40}>
				<Header>
					Tack för ditt abonnemang
					{`${
						person?.GivenName && person.GivenName !== ' ' ? ` ${person.GivenName}` : ''
					}`}
					!
					<br />
				</Header>
				<Text>
					Du kan även hantera ditt abonnemang på Mina Sidor i ditt{' '}
					<Email href={REACT_APP_MY_PAGE_URL}>Fotbollskonto</Email>.
				</Text>
			</Card>

			<Card contentMaxWidth={676} paddingBottom={40}>
				<Box>
					<Button callToAction name="fotboll-play" action={buttonAction}>
						Tillbaka till Fotboll Play
					</Button>
					<Spacing />
					<Button name="my-page" action={buttonAction}>
						Fortsätt till Mina Sidor
					</Button>
				</Box>
				<AppLinks action={() => {}} />
			</Card>
		</Container>
	);
}

export default ThankYouPage;
