import React, { useEffect, useState, useCallback, MouseEvent } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getApps, initializeApp } from 'firebase/app';
import { Functions, getFunctions } from 'firebase/functions';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth, signInWithCustomToken } from 'firebase/auth';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import AppProvider from '@contexts/app';
import ProductProvider from './contexts/product';
import VersionPage from '@components/pages/VersionPage';
import LoginPage from '@components/pages/LoginPage';
import FAQPage from '@components/pages/FAQPage';
import InitPage from '@components/pages/InitPage';
import ErrorPage from '@components/pages/ErrorPage';
import {
	StartClubPPVFlow,
	StartClubSubscriptionFlow,
	StartSubscriptionFlow,
	StartSubscriptionOldFlow,
} from './flows';
import FallbackComponent from '@components/Fallback';
import FAQDialog from '@components/FAQ/FAQDialog';
import { Logger } from '@utils/logger';
import * as Sentry from '@sentry/react';
import AppGTMStart from '@components/AppGTMStart';
import BrandingProvider from './contexts/branding';
import PrivatePage from '@components/pages/PrivatePage';
import AppThemeProvider from './contexts/apptheme';
import styled from 'styled-components';
import { TokenData } from './types/types';

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	color: ${({ theme }) => theme.textColor};
	background-color: ${({ theme }) => theme.bgColor};
`;

const initialGtmParams: ISnippetsParams = {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!,
	injectScript: false,
};

if (
	process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH &&
	process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW
) {
	initialGtmParams.environment = {
		gtm_auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
		gtm_preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
	};
}

const logger = new Logger('App');

const {
	REACT_APP_FIREBASE_API_KEY,
	REACT_APP_FIREBASE_AUTH_DOMAIN,
	REACT_APP_FIREBASE_DATABASE_URL,
	REACT_APP_FIREBASE_PROJECT_ID,
	REACT_APP_FIREBASE_STORAGE_BUCKET,
	REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	REACT_APP_FIREBASE_APP_ID,
	REACT_APP_FIREBASE_MEASUREMENT_ID,
	REACT_APP_FIREBASE_FUNCTION_REGION,
} = process.env;
declare global {
	interface Window {
		app: unknown;
		firebaseAuth: unknown;
		firebaseFirestore: unknown;
		firebaseFunctions: unknown;
	}
}

const firebaseConfig = {
	apiKey: REACT_APP_FIREBASE_API_KEY,
	authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
	projectId: REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: REACT_APP_FIREBASE_APP_ID,
	measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function App() {
	const [showFaq, setShowFaq] = useState(false);
	const [tokens, setTokens] = useState<TokenData>();
	const [firebaseAuth, setFirebaseAuth] = useState<Auth | undefined>();
	const [firebaseFirestore, setFirebaseFirestore] = useState<Firestore | undefined>();
	const [firebaseFunctions, setFirebaseFunctions] = useState<Functions | undefined>();
	const [gtmParams, setGtmParams] = useState<ISnippetsParams>(initialGtmParams);

	useEffect(() => {
		if (getApps().length === 0) {
			const app = initializeApp(firebaseConfig);
			setFirebaseFunctions(getFunctions(app, REACT_APP_FIREBASE_FUNCTION_REGION));
			setFirebaseFirestore(getFirestore());
		}
		setFirebaseAuth(getAuth());

		// Delay injecting GTM until later after Cookiebot has been initialized
		const timeoutRef = setTimeout(() => {
			setGtmParams({
				...initialGtmParams,
				injectScript: true,
			});
		}, 1000); // Arbitrary timeout value

		return () => clearTimeout(timeoutRef);
	}, []);

	useEffect(() => {
		async function asyncSignInToFirebase() {
			if (tokens?.firebaseCustomToken && firebaseAuth) {
				await signInWithCustomToken(firebaseAuth, tokens.firebaseCustomToken);
			}
		}
		asyncSignInToFirebase();
	}, [tokens, firebaseAuth]);

	const showFAQ = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		setShowFaq(true);
	}, []);

	const closeFAQ = useCallback(() => {
		setShowFaq(false);
	}, []);

	return (
		<GTMProvider state={gtmParams}>
			<AppGTMStart />
			<AppProvider
				logger={logger}
				firebaseFirestore={firebaseFirestore}
				firebaseAuth={firebaseAuth}
				firebaseFunctions={firebaseFunctions}
				tokens={tokens}
				setTokens={setTokens}
				showFAQ={showFAQ}
			>
				<BrandingProvider>
					<AppThemeProvider>
						<ProductProvider>
							<Router>
								<div className="App" data-testid="App">
									<Sentry.ErrorBoundary fallback={FallbackComponent}>
										<Container role="main">
											<Routes>
												<Route
													path="/"
													element={<PrivatePage element={<InitPage />} />}
												/>
												<Route
													path="/:teamName"
													element={<PrivatePage element={<InitPage />} />}
												/>
												<Route
													path="/:teamName/ppv/*"
													element={
														<PrivatePage
															element={<StartClubPPVFlow />}
														/>
													}
												/>
												<Route
													path="/:teamName/subscription/*"
													element={
														<PrivatePage
															element={<StartClubSubscriptionFlow />}
														/>
													}
												/>
												<Route
													path="/subscription/*"
													element={
														<PrivatePage
															element={<StartSubscriptionFlow />}
														/>
													}
												/>
												<Route
													path="/subscription-legacy/*"
													element={
														<PrivatePage
															element={<StartSubscriptionOldFlow />}
														/>
													}
												/>
												<Route
													path="/login"
													element={
														<LoginPage location={window.location} />
													}
												/>
												<Route
													path="/login/:redirectOnSuccess"
													element={
														<LoginPage location={window.location} />
													}
												/>
												<Route
													path="/register"
													element={
														<LoginPage
															location={window.location}
															register
														/>
													}
												/>
												<Route path="/faq" element={<FAQPage />} />
												<Route path="/version" element={<VersionPage />} />
												<Route path="/error" element={<ErrorPage />} />
												<Route
													path="*"
													element={<ErrorPage status={404} />}
												/>
											</Routes>
										</Container>
										{showFaq && <FAQDialog onClose={closeFAQ} />}
									</Sentry.ErrorBoundary>
								</div>
							</Router>
						</ProductProvider>
					</AppThemeProvider>
				</BrandingProvider>
			</AppProvider>
		</GTMProvider>
	);
}

export default Sentry.withProfiler(App);
