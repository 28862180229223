import { base32Encode, base32Decode } from '@ctrl/ts-base32';
import { Buffer } from 'buffer';

export function encode(str: string): string {
	if (!str) {
		return '';
	}
	return base32Encode(Buffer.from(str));
}

export function decode(str: string): string {
	if (!str) {
		return '';
	}
	return Buffer.from(base32Decode(str)).toString();
}
