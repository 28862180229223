import React from 'react';
import styled, { css } from 'styled-components';

const StyledImg = styled.img<{
	width?: string;
	height?: string;
}>`
	display: inline-block;
	margin: 0;
	padding: 0;
	${(props) =>
		props.width
			? css`
					width: ${props.width};
			  `
			: ''}
	${(props) =>
		props.height
			? css`
					height: ${props.height};
			  `
			: ''}
`;

/**
 * Icon component.
 * TODO: Describe component.
 */
export default function Icon({
	src,
	className = undefined,
	label = 'Icon',
	width = 'auto',
	height = 'auto',
}: React.PropsWithoutRef<{
	src: string;
	className?: string;
	label?: string;
	width?: string;
	height?: string;
}>): JSX.Element {
	return (
		<StyledImg
			role="img"
			src={src}
			className={`Icon${className ? ` ${className}` : ''}`}
			aria-label={label}
			width={width}
			height={height}
		/>
	);
}
