import React from 'react';
import { encode } from '@utils/base32';
import { useApp } from '@contexts/app';
import { Navigate } from 'react-router-dom';
import { Logger } from '@utils/logger';

const logger = new Logger('PrivatePage');

interface Props {
	element: React.ReactElement;
}

const PrivatePage = ({ element }: Props): JSX.Element => {
	const { tokens } = useApp();

	if (!tokens) {
		const url = location.pathname + location.search;
		logger.debug(`No tokens available, redirecting to /login/${encode(url)}...`);
		return <Navigate to={`/login/${encode(url)}`} />;
	}

	return element;
};

export default PrivatePage;
