import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input<{
	name?: string;
	value?: string;
	checked?: boolean;
}>`
	appearance: none;
	position: relative;
	cursor: pointer;

	:before {
		content: '';
		display: block;
		width: 14px;
		height: 14px;
		border: 2px solid #8d8d8d;
		border-radius: 3px;
		background-color: transparent;
	}
	:checked:before {
		content: '';
		display: block;
		width: 14px;
		height: 14px;
		border: 2px solid ${({ theme }) => theme.accent};
		background-color: ${({ theme }) => theme.accent};
	}
	:checked:after {
		content: '';
		display: block;
		width: 5px;
		height: 10px;
		border: solid #fff;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 1px;
		left: 5px;
	}
`;

export default function Checkbox({
	name = undefined,
	value = undefined,
	checked = false,
	change = undefined,
}: React.PropsWithoutRef<{
	name?: string;
	value?: string;
	checked?: boolean;
	change?: (checked: boolean) => void;
}>): JSX.Element {
	const [isChecked, setIsChecked] = useState(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	return (
		<StyledInput
			type="checkbox"
			role="checkbox"
			className="Checkbox"
			name={name}
			id={name}
			defaultValue={value}
			checked={isChecked}
			onChange={() => {
				setIsChecked(!isChecked);
				if (change) {
					change(!isChecked);
				}
			}}
		/>
	);
}
