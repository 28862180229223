import React from 'react';
import styled from 'styled-components';
import { useBranding } from '@contexts/branding';

const Title = styled.h2.attrs({ role: 'heading' })`
	margin: 0;
	padding: 0;
	font-size: 30px;
	margin-bottom: 10px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	color: ${(props) => props.theme.textColor};
	@media (max-width: 640px) {
		font-size: 22px;
	}
`;

const Price = styled.h2.attrs({ role: 'heading' })`
	margin: 0;
	padding: 0;
	font-size: 30px;
	margin-bottom: 10px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	color: ${(props) => props.theme.textColor};
	@media (max-width: 640px) {
		font-size: 22px;
	}
`;

const Text = styled.p`
	color: ${(props) => props.theme.textColor};
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	font-family: Gilroy-Regular;
	font-weight: normal;
	font-style: normal;
	max-width: 498px;
	@media (max-width: 640px) {
		font-size: 13px;
	}
`;

const Container = styled.div`
	background-color: ${(props) => props.theme.insertBackground};
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-right: 10px;
	padding-left: 10px;
`;

interface Props {
	title: string;
	price: number;
	currency: string;
}

export default function InfoCard({ title, price, currency }: Props): JSX.Element {
	const { teamAbbrv, merchant, strings } = useBranding();
	const nameOrAbbrv = merchant?.name || teamAbbrv || '';

	return (
		<Container>
			<Title>{title}</Title>
			<Price>{`${price} ${currency} inkl moms`}</Price>
			{strings?.subscriptionPremium?.paymentProductInformation && (
				<Text>
					{strings.subscriptionPremium.paymentProductInformation.replace(
						'$clubName',
						nameOrAbbrv
					)}
				</Text>
			)}
		</Container>
	);
}
