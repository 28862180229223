import React from 'react';
import styled, { css, CSSObject } from 'styled-components';

const StyledDiv = styled.div`
	color: ${({ theme }) => theme.textColor};
	background-color: ${({ theme }) => theme.bgColor};
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: auto;
	${(props) => (props.style ? css(props.style as CSSObject) : '')}
`;

/**
 * Generic container component.
 * TODO: Describe component.
 */
export default function Container({
	role = undefined,
	className = undefined,
	style = undefined,
	children,
}: React.PropsWithChildren<{
	role?: string;
	className?: string;
	style?: CSSObject;
}>): JSX.Element {
	return (
		<StyledDiv
			role={role}
			className={`Container${className ? ` ${className}` : ''}`}
			style={style}
		>
			{children}
		</StyledDiv>
	);
}
