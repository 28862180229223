import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Logger } from '@utils/logger';
import { useApp } from '@contexts/app';
import KlarnaCard from '@components/KlarnaCard';
import Container from '@components/Container';
import Spinner from '@components/Spinner';
import Card from '@components/Card';
import { useProduct } from '@contexts/product';
import { KlarnaPaymentsTypes } from '@klarna/payments/types';
import CEE from '@api/cee/ceeV3';
import { CeeNewKlarnaIntegrationError } from '@api/cee/error';
import KlarnaAlert from '@components/KlarnaAlert';
import InfoCard from '../../components/InfoCard';
import useRedirect from '@hooks/useRedirect';
import { AlertError } from '../../../../../types/types';
import { ErrorMessages } from '@data/constants';
import { FLOWS } from '../../../../../types/types.d';
import PaymentInfo from '@components/PaymentInfo';
import useOrder, { OrderType } from '@hooks/useOrder';
import { logInfo, logError } from '@utils/logtail';

const logger = new Logger('KlarnaPageClubSubscription');

const KlarnaPageClubSubscription = () => {
	const { teamName } = useParams<{
		teamName: string;
	}>();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { tokens, setCEEOrder } = useApp();
	const { productId, product, hasAccessToProduct } = useProduct();
	const { redirectIfMissingProductId, redirectIfAlreadyAccess } = useRedirect();
	const [error, setError] = useState<AlertError | null>(null);
	const orderType: OrderType = hasAccessToProduct ? 'update' : 'new';

	useEffect(() => {
		redirectIfMissingProductId(FLOWS.CLUB_SUBSCRIPTION, productId);
	}, [hasAccessToProduct, productId, redirectIfAlreadyAccess, redirectIfMissingProductId]);

	const [order, loading, orderError] = useOrder(orderType, product, true);

	useEffect(() => {
		if (orderError && !error) {
			setError(orderError);
		}
	}, [orderError, error]);

	/*
	console.log('KlarnaPageClubSubscription - orderType', orderType);
	console.log('KlarnaPageClubSubscription - product', product);
	console.log('KlarnaPageClubSubscription - order', order);
	console.log('KlarnaPageClubSubscription - loading', loading);
	console.log('KlarnaPageClubSubscription - orderError', orderError);
	*/

	const onKlarnaOrderCompleted = async (
		authorizationResponse: KlarnaPaymentsTypes.AuthorizeResponseData
	) => {
		if (!tokens || !order) {
			return;
		}

		const { authorization_token: klarnaToken } = authorizationResponse;

		try {
			if (!klarnaToken) {
				throw new Error('Klarna authorization not completed');
			}

			if (orderType === 'new') {
				await CEE.completeOrder(
					tokens.PersonId,
					tokens.accessToken,
					klarnaToken,
					order.ceeOrder.Id,
					order.paymentInfo
				);
				logger.debug('Order completed in CEE!');
				logInfo('Club subcscription order completed in CEE!');
				setCEEOrder(order.ceeOrder);
				navigate(
					{
						pathname: `/${teamName}/subscription/thank-you`,
						search: searchParams.toString(),
					},
					{
						replace: true,
					}
				);
			} else {
				await CEE.completeChangePayment(
					tokens.PersonId,
					tokens.accessToken,
					klarnaToken,
					order.ceeOrder.Id,
					order.paymentInfo
				);
				logger.debug('Change payment completed in CEE!');
				logInfo('Club subcscription change payment completed in CEE!');
				setCEEOrder(order.ceeOrder);
				navigate(
					{
						pathname: `/${teamName}/subscription/thank-you`,
						search: searchParams.toString(),
					},
					{
						replace: true,
					}
				);
			}
		} catch (err: unknown) {
			console.error('Completing order failed!', err);
			logError('Completing order failed!', { errorMessage: error });
			if (err instanceof CeeNewKlarnaIntegrationError) {
				const ceeError = err.getCeeIntegrationError();
				console.log('CEE Integration Error:', ceeError);
				setError({
					...(ceeError?.ceeError?.Details?.PaymentProviderError
						? ErrorMessages.klarna
						: ErrorMessages.default),
					ref: ceeError?.ceeError?.CorrelationId,
				});
				Sentry.withScope((scope) => {
					scope.setExtra('CEE Error', ceeError);
					Sentry.captureException(err);
				});
			} else {
				Sentry.captureException(err);
				setError(ErrorMessages.default);
			}
			throw err;
		}
	};

	return (
		<Container role="document">
			{product && (
				<InfoCard
					title={product?.DisplayName}
					price={product?.PricePlans[0]?.PriceAmount}
					currency={product?.PricePlans[0]?.PriceCurrency}
				/>
			)}
			<Card contentMaxWidth={606} leftAligned paddingTop={60} paddingBottom={60} flex>
				{error && <KlarnaAlert error={error} />}
				{loading && (
					<Card>
						<Spinner />
					</Card>
				)}
				{order && product && !loading && (
					<KlarnaCard
						order={order}
						product={product}
						buttonText={
							orderType === 'new' ? 'Slutför beställningen' : 'Ändra betalningsmetod'
						}
						onKlarnaOrderCompleted={onKlarnaOrderCompleted}
						setError={setError}
						paymentDetails={<PaymentInfo />}
					/>
				)}
			</Card>
		</Container>
	);
};

export default KlarnaPageClubSubscription;
