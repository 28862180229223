import styled from 'styled-components';

// New button as styled component only. Consider replace Button with this
export const StyledButton = styled.button`
	background-color: ${(props) => props.theme.cta};
	color: white;
	display: flex;
	flex-grow: 1;
	height: 50px;
	align-items: center;
	justify-content: center;
	border: 0;
	border-radius: 4px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	font-size: 16px;
	cursor: pointer;
	&[disabled] {
		color: rgba(0, 0, 0, 0.26);
		background-color: rgba(0, 0, 0, 0.12);
	}
	@media (max-width: 640px) {
		height: 44px;
	}
`;
