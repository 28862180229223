import React from 'react';
import styled from 'styled-components';
import FAQ from '@components/FAQ';
import articles from '@data/faq-articles';
import { H2 } from '@components/Parts';
import { fonts } from '../../style';
import Modal from '../Modal';

const Header = styled(H2)`
	color: ${(props) => props.theme.accent};
	${fonts.BOLD.css}
	font-size: 30px;
	margin-left: 35px;

	@media (max-width: 640px) {
		font-size: 22px;
		margin-left: 20px;
	}
`;

interface Props {
	onClose: () => void;
}

export default function FAQDialog({ onClose }: Props) {
	return (
		<Modal onClose={onClose} fullWidth>
			<Header>FAQ</Header>
			<FAQ articles={articles} />
		</Modal>
	);
}
