import React from 'react';
import styled from 'styled-components';
import { P } from './Parts';
import { ReactComponent as AlertIconSrc } from '@images/alert.svg';
import { AlertError } from '../types/types';

const Container = styled.div`
	background-color: #fdecea;
	padding: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 4px;
	margin-bottom: 30px;
`;

const AlertIcon = styled(AlertIconSrc)`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	.icon {
		fill: #c60001;
	}
`;

const InnerContainer = styled.div`
	padding-left: 15px;
`;

const Title = styled.p`
	color: #c60001;
	font-size: 16px;
	margin: 0;
	font-family: Gilroy-Bold;
`;

const Text = styled(P)`
	color: #741515;
	font-size: 13px;
	font-family: Gilroy-Bold;
	margin-top: 5px;
	& > a {
		color: #741515;
	}
`;

const Ref = styled(Text)`
	color: #917c7c;
	font-size: 12px;
`;

interface Props {
	error: AlertError;
}

const KlarnaAlert = ({ error }: Props) => {
	const { title, text, ref } = error;
	return (
		<Container>
			<AlertIcon />
			<InnerContainer>
				<Title>{title}</Title>
				{text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
				{ref && <Ref>Referens: {ref}</Ref>}
			</InnerContainer>
		</Container>
	);
};

export default KlarnaAlert;
