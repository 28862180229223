import React, { useEffect, useState } from 'react';
import { useApp } from '@contexts/app';
import Container from '@components/Container';
import Card from '@components/Card';
import { Logger } from '@utils/logger';

const { REACT_APP_VERSION } = process.env;
const logger = new Logger('VersionPage');

export default function VersionPage(): JSX.Element {
	const { tokens, person, signOut } = useApp();
	const [brokenRender, setBrokenRender] = useState(false);
	const handleCreateError = () => {
		let thisFunctionDoesNotExist: () => void;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		thisFunctionDoesNotExist!();
	};

	useEffect(() => {
		logger.info('Init useEffect', { someData: 'Does Sentry capture this for us?' });
	}, []);

	if (brokenRender) {
		throw new Error('Unknown error from VersionPage');
	}

	return (
		<Container role="document" className="KlarnaPage">
			<Card contentMaxWidth={769} leftAligned>
				Version: {REACT_APP_VERSION}
				<br />
				<br />
				<button type="button" onClick={handleCreateError}>
					Create error for Sentry
				</button>
				<br />
				<button type="button" onClick={() => setBrokenRender(true)}>
					Break this component render
				</button>
				<br />
				{person && tokens && (
					<>
						<h2>You are signed in as</h2>
						<div>{person.GivenName}</div>
						<div>{person.Email}</div>
						<div>{person.CeePersonId}</div>
						<button
							type="button"
							onClick={() =>
								signOut(window.location.origin + window.location.pathname)
							}
						>
							Sign out of CEE and Firebase
						</button>
					</>
				)}
			</Card>
		</Container>
	);
}
