import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as LogoSrc } from '@images/fotbollskonto-logo-pos.svg';
import { useLocation } from 'react-router-dom';

const Container = styled.header<{
	noBorder?: boolean;
}>`
	position: relative;
	flex-shrink: 0;
	background-color: ${(props) => props.theme.topbar};
	color: ${(props) => props.theme.topbarTextColor};
	height: 74px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 26px;
	text-transform: uppercase;
	${(props) =>
		!props.noBorder &&
		css`
			border-bottom: 5px solid ${props.theme.topbarBorder};
		`}
	@media (max-width: 480px) {
		height: 60px;
		font-size: 20px;
		${(props) =>
			!props.noBorder &&
			css`
				border-bottom-width: 4px;
			`}
	}
`;

const FotbollsKontoLogo = styled(LogoSrc)`
	height: 38px;
	@media (max-width: 480px) {
		height: 28px;
	}
	.icon {
		fill: ${(props) => props.theme.topbarTextColor};
	}
`;

interface Props {
	noBorder?: boolean;
}

export default function TopBar({ noBorder }: Props): JSX.Element {
	const location = useLocation();

	if (!location.pathname.includes('/subscription') && !location.pathname.includes('/ppv')) {
		return <></>;
	}

	return (
		<Container noBorder={noBorder}>
			<FotbollsKontoLogo />
		</Container>
	);
}
