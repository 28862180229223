import { KlarnaPaymentsTypes } from '../types';

/**
 * Klarna order class.
 */
export default class KlarnaOrder {
	currency: string;

	country: string;

	locale: string;

	amount: number;

	taxAmount: number;

	orderLines: KlarnaPaymentsTypes.OrderLine[];

	merchant_reference2: string;

	constructor(
		currency: string,
		country: string,
		locale: string,
		amount: number,
		taxAmount: number,
		reference = ''
	) {
		this.currency = currency;
		this.country = country;
		this.locale = locale;
		this.amount = amount;
		this.taxAmount = taxAmount;
		this.orderLines = [];
		this.merchant_reference2 = reference;
	}

	addOrderLine(orderLine: KlarnaPaymentsTypes.OrderLine): KlarnaOrder {
		this.orderLines.push(orderLine);
		return this;
	}

	createRequestBody(): KlarnaPaymentsTypes.Order {
		return {
			purchase_currency: this.currency,
			purchase_country: this.country,
			locale: this.locale,
			order_amount: this.amount,
			order_tax_amount: this.taxAmount,
			order_lines: this.orderLines,
			merchant_reference2: this.merchant_reference2,
		};
	}
}
