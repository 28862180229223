import React, { useEffect } from 'react';
import { useParams, useSearchParams, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useProduct } from '@contexts/product';
import { useBranding } from '@contexts/branding';
import { useApp } from '@contexts/app';
import { FLOWS } from '../../types/types.d';
import Card from '@components/Card';
import Spinner from '@components/Spinner';
import { H4 as PartH4, P } from '@components/Parts';
import useRedirectPPV from '@hooks/useRedirect';
// import { HIGHLIGHTS_PLUS_ID } from '@data/constants';
import { Logger } from '@utils/logger';

const logger = new Logger('InitPage');

const { REACT_APP_FOTBOLL_PLAY_URL, REACT_APP_HIGHTLIGHTS_PLUS_V3_ID } = process.env as Record<
	string,
	string
>;

const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const H4 = styled(PartH4)`
	margin-top: 16px;
	margin-bottom: 8px;
	@media (max-width: 640px) {
		margin-top: 10px;
		margin-bottom: 6px;
	}
`;

function InitPage(): JSX.Element {
	const { person, loading: loadingPerson, flow, setFlow } = useApp();
	const {
		setProductId,
		product,
		productId,
		loading: loadingProduct,
		error: errorProduct,
		redirectUrl,
		setRedirectUrl,
	} = useProduct();
	const { setTeamName, loading: loadingBranding } = useBranding();
	const { teamName } = useParams();
	const [searchParams] = useSearchParams();
	const { redirectOnError } = useRedirectPPV();
	const loading = loadingBranding || loadingPerson || loadingProduct;

	useEffect(() => {
		if (!productId) {
			const id = searchParams.get('productid');
			if (id) {
				setProductId(id);
			} else {
				logger.debug(
					'InitPage - No product id param found. Defaulting to Highlights Plus',
					REACT_APP_HIGHTLIGHTS_PLUS_V3_ID
				);
				setProductId(REACT_APP_HIGHTLIGHTS_PLUS_V3_ID);
				// logger.debug(
				// 	'InitPage - No product id param found. Defaulting to Highlights Plus',
				// 	HIGHLIGHTS_PLUS_ID
				// );
				// setProductId(HIGHLIGHTS_PLUS_ID);
			}
		}
		if (!redirectUrl) {
			const url = searchParams.get('redirectUrl');
			if (url) {
				const fullUrl = new URL(url || '', REACT_APP_FOTBOLL_PLAY_URL as string);
				setRedirectUrl(fullUrl.toString());
			}
		}
	}, [productId, searchParams, setProductId, redirectUrl, setRedirectUrl, redirectOnError]);

	useEffect(() => {
		if (teamName) {
			setTeamName(teamName);
		}
	}, [teamName, setTeamName]);

	useEffect(() => {
		// FIXME This is a bit messy
		if (product && !loadingProduct) {
			let newFlow = null;

			// if (product.Id === HIGHLIGHTS_PLUS_ID) {
			//   newFlow = FLOWS.SUBSCRIPTION_LEGACY;
			if (product.Id === REACT_APP_HIGHTLIGHTS_PLUS_V3_ID) {
				newFlow = FLOWS.SUBSCRIPTION;
			} else {
				if (teamName) {
					if (product.BillingType === 'OneTime') {
						newFlow = FLOWS.CLUB_PPV;
					} else if (product.BillingType === 'Recurring') {
						newFlow = FLOWS.CLUB_SUBSCRIPTION;
					}
				} else {
					logger.error('Unknown flow!');
				}
			}
			logger.debug('Product is loaded -> setting flow:', newFlow);
			setFlow(newFlow);
		}
	}, [loadingProduct, product, productId, setFlow, teamName]);

	useEffect(() => {
		if (!person && !loading) {
			redirectOnError('InitPage - Person is missing!');
		}
		if (!loadingProduct && errorProduct) {
			redirectOnError('InitPage - Product is missing!', {
				subtitle: 'Produkten kunde inte hittas',
			});
		}
	}, [errorProduct, loadingProduct, loading, product, person, redirectOnError, redirectUrl]);

	if (productId && product && !loading && flow !== null) {
		logger.debug('Redirecting to:', flow);
		if (flow === FLOWS.SUBSCRIPTION_LEGACY) {
			return (
				<Navigate
					to={{
						pathname: '/subscription-legacy',
						search: searchParams.toString(),
					}}
				/>
			);
		} else if (flow === FLOWS.SUBSCRIPTION) {
			return (
				<Navigate
					to={{
						pathname: '/subscription',
						search: searchParams.toString(),
					}}
				/>
			);
		} else if (flow === FLOWS.CLUB_PPV) {
			return (
				<Navigate
					to={{
						pathname: `/${teamName}/ppv`,
						search: searchParams.toString(),
					}}
				/>
			);
		} else if (flow === FLOWS.CLUB_SUBSCRIPTION) {
			return (
				<Navigate
					to={{
						pathname: `/${teamName}/subscription`,
						search: searchParams.toString(),
					}}
				/>
			);
		}
	}

	return (
		<Container role="document">
			<Card>
				<Spinner />
				<H4>Hämtar information</H4>
				<P>Det kan ta några sekunder</P>
			</Card>
		</Container>
	);
}

export default InitPage;
