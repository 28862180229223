import React from 'react';
import styled from 'styled-components';
import { useBranding } from '@contexts/branding';

const Container = styled.div`
	margin-bottom: 30px;
`;

const Logo = styled.img`
	width: 38px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const Text = styled.p`
	margin: 0px;
	margin-bottom: 5px;
	font-family: Gilroy-Regular;
	font-size: 12px;
	color: #606060;
	& > a {
		color: #606060;
		font-family: Gilroy-Regular;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const Header = styled.p`
	margin: 0;
	font-size: 16px;
	font-family: Gilroy-Bold;
	@media (max-width: 640px) {
		font-size: 13px;
	}
	color: #606060;
`;

interface Props {
	title?: string;
}

const MerchantInfo = ({ title }: Props) => {
	const { merchant, logo } = useBranding();

	return (
		<Container>
			{title && <Header>{title}</Header>}
			{logo && <Logo src={logo} alt={merchant?.name} />}
			{merchant && (
				<>
					<TextContainer>
						<Text>{merchant.name},&nbsp;</Text>
						<Text>{merchant.address},&nbsp;</Text>
						<Text>
							{merchant.zipCode}&nbsp;{merchant.city}
						</Text>
					</TextContainer>
					<TextContainer>
						<Text>Org nr {merchant.orgNr},&nbsp;</Text>
						<Text>
							<a href={'mailto: ' + merchant.supportEmail}>{merchant.supportEmail}</a>
							,&nbsp;
						</Text>
						<Text>{merchant.phoneNumber}</Text>
					</TextContainer>
				</>
			)}
		</Container>
	);
};

export default MerchantInfo;
