import { AlertError } from '../types/types';

// eslint-disable-next-line import/prefer-default-export
export const SEF = {
	copyright: '© Svensk Elitfotboll 2024',
	url: 'https://www.svenskelitfotboll.se/',
	urlShort: 'svenskelitfotboll.se',
	helpEmail: 'dpo@svenskelitfotboll.se',
	supportEmail: 'support@svenskelitfotboll.se',
};

export const HIGHLIGHTS_PLUS_ID = 'highlightsplus';

export const PRODUCTS = {
	HIGHLIGHTS_PLUS: {
		productName: 'Highlights Plus',
		monthlyCost: 35,
		sefUrl: 'https://www.svenskelitfotboll.se/',
		sefHelpEmail: 'dpo@svenskelitfotboll.se', // TODO remove this and others
		sefSupportEmail: 'support@svenskelitfotboll.se',
		commonTermsUrl: 'https://www.svenskelitfotboll.se/allmanna-villkor/',
		specialTermsUrl: 'https://www.svenskelitfotboll.se/sarskilda-villkor-highlights-plus/',
		integrityPolicyUrl: 'https://www.svenskelitfotboll.se/integritetspolicy/',
		fotbollskontoUrl: process.env.REACT_APP_FOTBOLLSKONTO_URL,
		checkoutUrl: 'http://checkout.svenskelitfotboll.se',
		klarnaLoginUrl: 'https://app.klarna.com/login',
		allsvenskanUrl: 'https://highlights.allsvenskan.se/',
		superettanUrl: 'https://highlights.superettan.se/',
		futsalliganUrl: 'https://highlights.svenskafutsalligan.se/',
		allsvenskanAppStoreUrl:
			'https://apps.apple.com/se/app/allsvenskan-live-officiell/id546286760',
		allsvenskanGooglePlayUrl:
			'https://play.google.com/store/apps/details?id=se.allsvenskan.android',
		superettanAppStoreUrl: 'https://apps.apple.com/se/app/superettan-live/id546287922',
		superettanGooglePlayUrl:
			'https://play.google.com/store/apps/details?id=se.superettan.android',
	},
};

export const ErrorMessages: Record<string, AlertError> = {
	default: {
		title: 'Vi beklagar, men vi kan inte behandla din betalning för tillfället',
		text: 'Försök igen senare. Om du upplever återkommande problem, vänligen kontakta <a href="mailto: support@svenskelitfotboll.se" target="_blank">supporten</a>',
	},
	klarna: {
		title: 'Vi har problem med att bearbeta din betalning med Klarna',
		text: 'Försök igen senare eller välj en annan betalningsmetod. Om du upplever återkommande problem, vänligen kontakta <a href="https://www.klarna.com" target="_blank">Klarna supporten</a>',
	},
	timeout: {
		title: 'Din betalningsprocess tog för lång tid och tidsgränsen överskreds',
		text: 'Försök igen. Om du upplever återkommande problem, vänligen kontakta <a href="mailto: support@svenskelitfotboll.se" target="_blank">supporten</a>',
	},
};
