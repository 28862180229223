import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@components/Button';
import Card from '@components/Card';
import Container from '@components/Container';
import Form from '@components/Form';
import HighlightsPlusInfoCard from '../../components/HighlightsPlusInfoCard';
import Spinner from '@components/Spinner';
import { useApp } from '@contexts/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import styled from 'styled-components';
import { POPATypes } from '@api/popa/types';
import AccountFAQInfo from '@components/AccountFaqInfo';
import { ContentP, ColumnFieldset, Fieldset, PrimaryLabel } from './style';
import { collection, Query } from 'firebase/firestore';
import Select, { Option } from '@components/Select';
import Checkbox from '@components/Checkbox';

type Team = POPATypes.Team;
type TeamLogo = Record<string, string | undefined>;

const Logo = styled.div`
	width: 22px;
	height: 22px;
	margin-right: 8px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
`;

const Spacing = styled.div`
	height: 15px;
`;

const LabeledToggle = styled.div`
	display: flex;
	align-items: center;

	> span {
		font-family: Gilroy-Regular;
		font-weight: 400;
		line-height: 1.5;
		font-size: 13px;
		margin-left: 10px;
	}
`;

const noTeam: Team = { abbrv: '0', everySportId: '0', name: 'Ingen vald klubb' };
const {
	REACT_APP_INTEGRITY_POLICY_URL,
	REACT_APP_TEAM_INTEGRITY_POLICY_URL,
	REACT_APP_HIGHTLIGHTS_PLUS_PRIVACY_TERMS_OF_USE_URL,
	REACT_APP_FIRESTORE_ALLSVENSKAN_TEAMS_PATH,
	REACT_APP_FIRESTORE_SUPERETTAN_TEAMS_PATH,
	REACT_APP_FIRESTORE_TEAMS_PATH,
} = process.env;

type TeamOptions = {
	allsvenskan: Option[];
	superettan: Option[];
};

const toOption = (team: any, teamLogo?: string): Option => ({
	value: team.name,
	label: team.name,
	icon: teamLogo ? (
		<Logo
			style={{
				backgroundImage: `url('${teamLogo}')`,
			}}
		/>
	) : null,
});

function ChooseTeamPage(): JSX.Element {
	const { abbrv, acceptNewsLetter } = useParams<{
		abbrv: string;
		acceptNewsLetter: string;
	}>();
	const navigate = useNavigate();
	const appContext = useApp();
	const { firebaseFirestore } = appContext;
	const refAllsvenskanTeamsCollection = firebaseFirestore
		? collection(firebaseFirestore, REACT_APP_FIRESTORE_ALLSVENSKAN_TEAMS_PATH || '')
		: null;
	const [allsvenskanTeams] = useCollection<Team>(refAllsvenskanTeamsCollection as Query<Team>);
	const refSuperettanTeamsCollection = firebaseFirestore
		? collection(firebaseFirestore, REACT_APP_FIRESTORE_SUPERETTAN_TEAMS_PATH || '')
		: null;
	const [superettanTeams] = useCollection<Team>(refSuperettanTeamsCollection as Query<Team>);
	const refTeamsCollection = firebaseFirestore
		? collection(firebaseFirestore, REACT_APP_FIRESTORE_TEAMS_PATH || '')
		: null;
	const [allTeams] = useCollection<Team>(refTeamsCollection as Query<Team>);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [sortedTeams, setSortedTeams] = useState<{
		all: Team[];
		allsvenskanTeams: Team[];
		superettanTeams: Team[];
	}>({
		all: [noTeam],
		allsvenskanTeams: [],
		superettanTeams: [],
	});
	const [teamOptions, setTeamOptions] = React.useState<TeamOptions>({
		allsvenskan: [],
		superettan: [],
	});
	const [selectedTeam, setSelectedTeam] = useState<Team | null>(
		abbrv === undefined || abbrv === 'undefined' || abbrv === '' ? noTeam : null
	);

	const onChangeTeam = (name?: string, value?: string) => {
		const foundTeam = sortedTeams.all.find((t) => t.name === value);
		if (foundTeam) {
			setSelectedTeam(foundTeam);
		}
		navigate(
			`/subscription-legacy/choose-team/${foundTeam?.abbrv}/${
				acceptNewsLetter ? 'on' : 'off'
			}`,
			{
				replace: true,
			}
		);
	};

	const onAcceptNewsLetter = (checked: boolean) => {
		if (selectedTeam) {
			navigate(
				`/subscription-legacy/choose-team/${selectedTeam.abbrv}/${checked ? 'on' : 'off'}`,
				{
					replace: true,
				}
			);
		}
	};

	const onAcceptTerms = (checked: boolean) => {
		if (termsAccepted !== checked) {
			setTermsAccepted(checked);
		}
	};

	const onConfirm = () => {
		if (selectedTeam === noTeam) {
			navigate('/subscription-legacy/payment');
		} else if (selectedTeam) {
			navigate(`/subscription-legacy/payment/${abbrv}/${acceptNewsLetter}`);
		}
	};

	// When we get the teams, check if we can change value in Select to the correct team
	useEffect(() => {
		if (allsvenskanTeams && superettanTeams && allTeams) {
			const allsvenskanTeamsSorted =
				allsvenskanTeams?.docs
					.sort((a, b) => a.data().name.localeCompare(b.data().name))
					.map((d) => d.data()) || [];

			const superettanTeamsSorted =
				superettanTeams?.docs
					.sort((a, b) => a.data().name.localeCompare(b.data().name))
					.map((d) => d.data()) || [];

			setSortedTeams({
				all: [noTeam, ...allsvenskanTeamsSorted, ...superettanTeamsSorted],
				allsvenskanTeams: allsvenskanTeamsSorted,
				superettanTeams: superettanTeamsSorted,
			});

			const allTeamsLogos = allTeams?.docs
				.map((d) => d.data())
				.reduce((acc: TeamLogo, team: any) => {
					return {
						...acc,
						[team.abbrv]: team.logoImageUrl,
					};
				}, {} as TeamLogo);

			const allsvenskanTeamsOptions = allsvenskanTeamsSorted.map((team: any) =>
				toOption(team, allTeamsLogos[team.abbrv])
			);
			const superettanTeamsOptions = superettanTeamsSorted.map((team: any) =>
				toOption(team, allTeamsLogos[team.abbrv])
			);
			setTeamOptions({
				allsvenskan: allsvenskanTeamsOptions,
				superettan: superettanTeamsOptions,
			});
		}
	}, [allsvenskanTeams, superettanTeams, allTeams]);

	useEffect(() => {
		if (
			sortedTeams &&
			sortedTeams.all.length > 1 &&
			!sortedTeams.all.find((t) => t.abbrv === abbrv) &&
			selectedTeam !== noTeam
		) {
			setSelectedTeam(noTeam);
			navigate(`/subscription-legacy/choose-team`, { replace: true });
		}
	}, [selectedTeam, sortedTeams, abbrv, navigate]);

	useEffect(() => {
		if (sortedTeams && sortedTeams.all.length > 1) {
			const selTeam = sortedTeams.all.find((t) => t.abbrv === abbrv);
			if (selTeam) {
				setSelectedTeam(selTeam);
			}
		}
	}, [abbrv, sortedTeams]);

	if (sortedTeams.all.length <= 1) {
		return (
			<Container role="document" className="ChooseTeamPage">
				<HighlightsPlusInfoCard />

				<Card contentMaxWidth={632} paddingTop={60}>
					<ContentP>Vi laddar in lagen i Allsvenskan och Superettan.</ContentP>
					<Spinner size={3} />
				</Card>
			</Container>
		);
	}

	// const options = sortedTeams.allsvenskanTeams.map((team) => {
	// 	return {
	// 		label: team.name,
	// 		options: teamOptions.allsvenskan,
	// 	};
	// });

	return (
		<Container role="document" className="ChooseTeamPage">
			<HighlightsPlusInfoCard />

			<Card contentMaxWidth={632} paddingTop={60} flex>
				<ContentP>
					Överskottet från din månadsavgift går till klubbarna och du kan välja en klubb
					som du önskar ska få ditt stöd. Gör du inget aktivt val kommer pengarna att
					fördelas mellan alla klubbar i Allsvenskan och Superettan.
				</ContentP>

				<div style={{ flex: '0 1 auto', alignSelf: 'stretch' }}>
					<Form>
						<ColumnFieldset>
							<PrimaryLabel>
								Välj vilken klubb du vill stötta (valfritt).
							</PrimaryLabel>
							<Select
								isClearable
								id="favoriteTeam"
								onChange={(option) => {
									const opt = option as Option | null;
									onChangeTeam(opt?.label, opt?.value);
								}}
								options={[
									{
										label: 'Allsvenskan',
										options: teamOptions.allsvenskan,
									},
									{
										label: 'Superettan',
										options: teamOptions.superettan,
									},
								]}
							/>
						</ColumnFieldset>
						<Spacing />
						{selectedTeam && selectedTeam.name !== noTeam.name && (
							<Fieldset style={{ maxWidth: 580, width: '100%' }}>
								<LabeledToggle>
									<Checkbox
										name="accept-newsletter"
										checked={acceptNewsLetter === 'on'}
										change={onAcceptNewsLetter}
									/>
									<span>
										Jag vill få nyhetsbrev från det lag som jag angivit ovan
										enligt lagets{' '}
										<a
											href={REACT_APP_TEAM_INTEGRITY_POLICY_URL}
											rel="noreferrer"
											target="_blank"
										>
											integritetspolicy
										</a>
										.
									</span>
								</LabeledToggle>
							</Fieldset>
						)}

						<Fieldset style={{ maxWidth: 580, width: '100%' }}>
							<LabeledToggle>
								<Checkbox
									name="accept-newsletter"
									checked={acceptNewsLetter === 'on'}
									change={onAcceptTerms}
								/>
								<span>
									Jag har läst och godkänner
									<a
										href={REACT_APP_HIGHTLIGHTS_PLUS_PRIVACY_TERMS_OF_USE_URL}
										rel="noreferrer"
										target="_blank"
									>
										användarvillkoren för Highlights Plus
									</a>
									. Läs mer om Svensk Elitfotbolls behandling av personuppgifter i
									vår{' '}
									<a
										href={REACT_APP_INTEGRITY_POLICY_URL}
										rel="noreferrer"
										target="_blank"
									>
										integritetspolicy
									</a>
									.
								</span>
							</LabeledToggle>
						</Fieldset>

						<div
							style={{
								marginTop: 34,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Button
								disabled={!termsAccepted}
								callToAction
								noBorder
								action={onConfirm}
								name="confirm"
							>
								Bekräfta och gå vidare
							</Button>
							<AccountFAQInfo />
						</div>
					</Form>
				</div>
			</Card>
		</Container>
	);
}

export default ChooseTeamPage;
