import { doc, DocumentReference, FirestoreError } from 'firebase/firestore';
import { useApp } from '@contexts/app';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { POPATypes } from '@api/popa/types';

export default function useTeam(teamAbbrv?: string) {
	const { firebaseFirestore } = useApp();

	const teamDocRef =
		teamAbbrv && firebaseFirestore ? doc(firebaseFirestore, `/teams/${teamAbbrv}`) : null;

	const [teamDoc, loading, error] = useDocumentOnce<POPATypes.Team | null>(
		teamDocRef as DocumentReference<POPATypes.Team | null>
	);

	const team = teamDoc?.data();

	return [team, loading, error] as [POPATypes.Team, boolean, FirestoreError];
}
