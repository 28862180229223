import React, { useState } from 'react';
import styled from 'styled-components';
import { ExpandArrow } from './IconNew';

interface AccordionProps {
	title: string;
	expanded: boolean;
	onChange: () => void;
	children: any;
}

const ContainerAccordion = styled.div`
	display: flex;
	flex-direction: column;
	border-top: 2px solid #f6f4f9;
	border-bottom: 2px #f6f4f9;
	:hover {
		cursor: pointer;
	}
`;

const Summary = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	color: ${(props) => props.theme.primaryDark};
	padding: 25px;
	padding-left: 35px;
	padding-right: 35px;
	text-align: left;
	@media (max-width: 640px) {
		padding: 20px;
	}
`;

const Details = styled.div`
	font-size: 14px;
	line-height: 23px;
	font-family: Gilroy-Regular;
	font-weight: normal;
	text-align: left;
	padding-left: 25px;
	padding-right: 25px;
	background-color: #faf9fc;
`;

export function Accordion({ children, title }: AccordionProps) {
	const [expanded, setExpanded] = useState(false);

	const onClick = (event: React.MouseEvent) => {
		event.preventDefault();
		setExpanded(!expanded);
	};

	return (
		<ContainerAccordion>
			<Summary onClick={onClick}>
				{title}
				<ExpandArrow
					size="medium"
					style={
						expanded ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }
					}
				/>
			</Summary>
			{expanded && <Details>{children}</Details>}
		</ContainerAccordion>
	);
}
