import React from 'react';
import styled from 'styled-components';
import { useBranding } from '@contexts/branding';

const { REACT_APP_PAYMENT_PLAY_CONDITIONS_URL } = process.env;

// Using Klarna CSS styling (copied)
const Info = styled.p`
	color: #5f6163;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
	margin-left: 8px;
	font-size: 12px;
	& > a {
		color: #0d0e0f;
		font-family: inherit;
	}
`;

const PaymentInfo = () => {
	const { teamAbbrv, merchant, strings } = useBranding();
	const nameOrAbbrv = merchant?.name || teamAbbrv || '';

	return (
		<Info
			dangerouslySetInnerHTML={{
				__html:
					strings?.ppv.paymentPolicies
						.replaceAll('$clubName', nameOrAbbrv)
						.replaceAll(
							'$paymentConditionsLink',
							REACT_APP_PAYMENT_PLAY_CONDITIONS_URL as string
						)
						.replaceAll('$clubPolicyLink', strings?.ppvClubPolicyLink) || '',
			}}
		></Info>
	);
};

export default PaymentInfo;
