import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button<{
	callToAction: boolean;
	fullWidth: boolean;
	noBorder: boolean;
}>`
	flex-shrink: 0;
	color: #101010;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 400px;
	align-self: center;
	height: 50px;
	padding: 0;
	background-color: #fff;
	border-width: 2px;
	border-radius: 4px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	font-size: 16px;
	border: 2px solid #eaeaea;
	cursor: pointer;
	&[disabled] {
		color: #c0c0c0;
		background-color: ${(props) => props.theme.disabledBackground};
		border-color: initial;
	}
	@media (max-width: 640px) {
		font-size: 14px;
		height: 44px;
	}
	${(props) =>
		props.callToAction &&
		css`
			background-color: ${props.theme.cta};
			color: #fff;
			border-color: ${props.theme.cta};
		`}
	${(props) =>
		props.fullWidth &&
		css`
			max-width: initial;
		`}
	${(props) =>
		props.color &&
		css`
			background-color: ${props.color};
		`}
	${(props) =>
		props.noBorder &&
		css`
			border-width: 0;
		`}
`;

/**
 * Button component.
 * TODO: Describe component.
 */
export default function Button({
	callToAction = false,
	fullWidth = false,
	name,
	action,
	children,
	disabled = false,
	noBorder = false,
	color,
}: React.PropsWithChildren<{
	name: string;
	action?: (event: React.MouseEvent<HTMLElement>, name: string) => void;
	children: React.ReactNode;
	callToAction?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
	noBorder?: boolean;
	color?: string;
}>): JSX.Element {
	const onClick = (event: React.MouseEvent<HTMLElement>): void => {
		event.preventDefault();
		if (action) {
			action(event, name);
		}
	};

	return (
		<StyledButton
			role="button"
			className="Button"
			callToAction={callToAction}
			type="button"
			name={name}
			onClick={onClick}
			fullWidth={fullWidth}
			disabled={disabled}
			color={color}
			noBorder={noBorder}
		>
			{children}
		</StyledButton>
	);
}
