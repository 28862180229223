import React, { useContext, useState, useMemo } from 'react';
import { useDocumentDataOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { useApp } from '@contexts/app';
import { ClubPalette } from '../style';
import { POPATypes } from '@api/popa/types';

interface BrandingContextProps {
	teamAbbrv: string | null;
	teamName: string | null;
	setTeamName: (name: string) => void;
	strings: Record<string, any> | null;
	team: any | null;
	palette: ClubPalette | null;
	logo: string | null;
	merchant: POPATypes.Merchant | null;
	loading: boolean;
}

export const BrandingContext = React.createContext<BrandingContextProps>({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setTeamName: (name) => {},
	teamName: null,
	teamAbbrv: null,
	strings: null,
	team: null,
	palette: null,
	logo: null,
	merchant: null,
	loading: true,
});

const BrandingProvider: React.FC<any> = ({ children }) => {
	const { firebaseFirestore } = useApp();
	const [teamName, setTeamName] = useState<string | null>(null);

	const stringDocRef = firebaseFirestore
		? doc(firebaseFirestore, `/checkout/fotbollplay/strings/sv`)
		: null;
	const [stringsData, stringsLoading] = useDocumentData(stringDocRef);

	const teamDocRef =
		firebaseFirestore && teamName
			? doc(firebaseFirestore, `/checkout/fotbollplay/teams/${teamName || 'default'}`)
			: null;
	const [teamData, teamLoading] = useDocumentDataOnce(teamDocRef);

	const strings = useMemo(() => {
		let mergedStrings = {};
		if (stringsData && teamData) {
			mergedStrings = {
				...stringsData,
				// ...teamData.strings?.sv,
			};
		}
		return mergedStrings;
	}, [stringsData, teamData]);

	// TODO Check error handling!

	return (
		<BrandingContext.Provider
			value={{
				teamName,
				setTeamName,
				strings,
				team: teamData,
				teamAbbrv: teamData?.abbrv || teamName, // TODO teamName is temp fallback for old abbrv uls
				palette: teamData?.palette,
				logo: teamData?.logo,
				merchant: teamData?.merchant,
				loading: stringsLoading || teamLoading,
			}}
		>
			{children}
		</BrandingContext.Provider>
	);
};

export const useBranding = () => useContext(BrandingContext);

export default BrandingProvider;
