import React from 'react';
import Container from '@components/Container';
import { Accordion } from '../Accordion';

interface Article {
	id: string;
	title: string;
	html: string;
}

function ArticleComponent({
	expanded,
	article,
	handleChange,
}: {
	expanded: boolean;
	article: Article;
	handleChange: () => void;
}): JSX.Element {
	return (
		<Accordion expanded={expanded} onChange={handleChange} title={article.title}>
			{/* eslint-disable-next-line react/no-danger */}
			<div dangerouslySetInnerHTML={{ __html: article.html }} />
		</Accordion>
	);
}

export default function FAQ({ articles }: { articles: Article[] }): JSX.Element {
	const [expandedId, setExpandedId] = React.useState<string>();

	return (
		<Container role="complementary" className="FAQ">
			{articles.map((article) => (
				<ArticleComponent
					expanded={expandedId === article.id}
					key={article.id}
					article={article}
					handleChange={() =>
						expandedId === article.id ? setExpandedId('') : setExpandedId(article.id)
					}
				/>
			))}
		</Container>
	);
}
