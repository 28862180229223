import React from 'react';
import { useApp } from '@contexts/app';
import styled from 'styled-components';
import { ReactComponent as AlertIconSrc } from '@images/alert.svg';

export interface ErrorMessageConfig {
	enabled: boolean;
	message: string;
	blocking: boolean;
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const InnerContainer = styled.div`
	flex: 1;
	max-width: 600px;
	background-color: #fdecea;
	padding: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 4px;
	margin: 20px;
`;

const AlertIcon = styled(AlertIconSrc)`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	.icon {
		fill: #c60001;
	}
`;

const Text = styled.p`
	color: #c60001;
	font-size: 16px;
	padding-left: 15px;
	margin: 0;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	text-align: left;
`;

const ErrorMessage = () => {
	const { errorMessageConfig } = useApp();

	return errorMessageConfig?.enabled ? (
		<Container>
			<InnerContainer>
				<AlertIcon />
				<Text>{errorMessageConfig.message}</Text>
			</InnerContainer>
		</Container>
	) : (
		<></>
	);
};

export default ErrorMessage;
