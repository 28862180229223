import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '@contexts/app';
import styled from 'styled-components';
import Card from '@components/Card';
import { H4 as PartH4, P } from '@components/Parts';
import { ReactComponent as LogoSrc } from '@images/fotbollskonto-logo-pos.svg';
import Modal from '@components/Modal';
import { StyledButton as Button } from './StyledButton';
import LoadingCard from '@components/LoadingCard';

const {
	REACT_APP_MY_PAGE_URL,
	REACT_APP_FOTBOLL_PLAY_URL,
	// eslint-disable-next-line prettier/prettier
} = process.env as unknown as {
	REACT_APP_MY_PAGE_URL: string;
	REACT_APP_FOTBOLL_PLAY_URL: string;
};

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	max-width: 400px;
	flex-direction: column;
	width: 100%;
`;

const ButtonLink = styled(Button)`
	background-color: transparent;
	border: 2px solid #eaeaea;
	color: #101010;
`;

const Padding = styled.div`
	height: 20px;
`;

const Logo = styled(LogoSrc)`
	width: 197px;
	margin-bottom: 15px;
	.icon {
		fill: ${(props) => props.theme.accent};
	}
`;

const H4 = styled(PartH4)`
	margin-top: 16px;
	margin-bottom: 8px;
	@media (max-width: 640px) {
		margin-top: 10px;
		margin-bottom: 6px;
		align-self: center;
	}
`;

interface Props {
	abbrv?: string;
	onClose: () => void;
	onChange: () => void;
}

export default function AlreadySubscribedDialog({ abbrv, onClose, onChange }: Props): JSX.Element {
	const { signOut } = useApp();
	// const navigate = useNavigate();
	const [isSigningOut, setIsSigningOut] = useState(false);

	// function handleChangeClick() {
	// 	navigate(`/subscription/choose-team/${abbrv || ''}`, { replace: true });
	// }

	function handleMyPageClick() {
		window.location.href = REACT_APP_MY_PAGE_URL;
	}

	function handleFotbollPlayClick() {
		window.location.href = REACT_APP_FOTBOLL_PLAY_URL;
	}

	async function handleRegister() {
		setIsSigningOut(true);
		const redirectUrl = window.location.origin + window.location.pathname;
		await signOut(redirectUrl);
	}

	return (
		<Modal onClose={onClose}>
			{isSigningOut && <LoadingCard text="Du loggas ut" />}

			{!isSigningOut && (
				<>
					<Card>
						<Logo />
						<P>
							Det Fotbollkonto du är inloggad med har redan ett abonnemang för
							Highlights Plus. Besök ditt Fotbollskonto för att se dina abonnemang
							eller logga ut från ditt konto för att starta ett abonnemang för ett
							annat Fotbollskonto.
						</P>
						<Padding />
						<ButtonContainer>
							<Button onClick={onChange}>Ändra betalsätt</Button>
							<Padding />
							<Button onClick={handleRegister}>
								Starta abonnemang med annat konto
							</Button>
							<Padding />
							<ButtonLink onClick={handleMyPageClick}>
								Fortsätt till Mina Sidor
							</ButtonLink>
							<Padding />
							<ButtonLink onClick={handleFotbollPlayClick}>
								Fortsätt till Fotboll Play
							</ButtonLink>
						</ButtonContainer>
					</Card>
				</>
			)}
		</Modal>
	);
}
