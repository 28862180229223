import React from 'react';
import styled from 'styled-components';
import AllsvenskanLiveIcon from '@images/allsvenskan-live.png';
import SuperettanLiveIcon from '@images/superettan-live.png';
import AppStoreImage from '@images/app-store@3x.png';
import GooglePlayImage from '@images/google-play@3x.png';
import Icon from '@components/Icon';

export const Box = styled.div`
	display: flex;
	justify-content: center;
	align-self: stretch;
	flex-direction: row;
	margin-bottom: 16px;
	@media (max-width: 732px) {
		flex-direction: column;
	}
`;

export const ImageBoxes = styled.div`
	display: flex;
	flex-direction: row;
	@media (max-width: 732px) {
		flex-direction: column;
		margin-bottom: 4px;
		align-self: stretch;
	}
`;

export const ImageBox = styled.div`
	display: flex;
	border: 2px solid #e9ecf2;
	border-radius: 3px;
	margin-bottom: 50px;
	padding: 16px 20px;
	align-items: center;
	justify-content: center;
	@media (max-width: 732px) {
		align-self: center;
		max-width: 400px;
		box-sizing: border-box;
		width: 100%;
		margin-bottom: 16px;
	}
`;

export const Padding = styled.div`
	width: 14px;
`;

interface Props {
	action: (type: 'allsvenskan' | 'superettan') => void;
}

const AppLinks = ({ action }: Props) => {
	return (
		<ImageBoxes>
			<ImageBox onClick={() => action('allsvenskan')}>
				<Icon src={AllsvenskanLiveIcon} label="A-Live" width="40px" height="40px" />
				<Padding />
				<img src={AppStoreImage} style={{ width: 98 }} alt="App Store" />
				<Padding />
				<img src={GooglePlayImage} style={{ width: 98 }} alt="Google Play" />
			</ImageBox>

			<Padding />

			<ImageBox onClick={() => action('superettan')}>
				<Icon src={SuperettanLiveIcon} label="S-Live" width="40px" height="40px" />
				<Padding />
				<img src={AppStoreImage} style={{ width: 98 }} alt="App Store" />
				<Padding />
				<img src={GooglePlayImage} style={{ width: 98 }} alt="Google Play" />
			</ImageBox>
		</ImageBoxes>
	);
};

export default AppLinks;
