import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { doc, DocumentReference } from 'firebase/firestore';
import * as Sentry from '@sentry/react';
import { CEETypes } from '@api/cee/types';
import { PRODUCTS } from '@utils/constants';
import { KlarnaPaymentsTypes } from '@klarna/payments/types.d';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Logger } from '@utils/logger';
import { useApp } from '@contexts/app';
import Container from '@components/Container';
import Card from '@components/Card';
import HighlightsPlusInfoCard from '../../components/HighlightsPlusInfoCard';
import CEE, { CeeKlarnaIntegrationError, CeeKlarnaIntegrationErrorCodes } from '@api/cee';
import { POPATypes } from '@api/popa/types';
import { H4 as PartH4 } from '@components/Parts';
import KlarnaAlert from '@components/KlarnaAlert';
import Payments from '@klarna/payments';
import Spinner from '../../../../components/Spinner';
import { httpsCallable } from 'firebase/functions';
import { StyledButton } from '../../components/StyledButton';
import { AlertError } from '../../../../types/types';

const logger = new Logger('KlarnaPage');
const WIDGET_CONTAINER_ID = 'Klarna-payments-container';

export const H3 = styled.h3`
	margin: 0 0 4px;
	padding: 0;
	font-size: 16px;
	font-weight: bold;
	color: ${({ theme }) => theme.textColor};
`;

export const P = styled.p`
	color: ${({ theme }) => theme.textColor};
	margin: 5px 0 15px 0;
	font-size: 16px;
`;

export const Klarna = styled.div`
	margin-top: 20px;
`;

const H4 = styled(PartH4)`
	margin-top: 16px;
	margin-bottom: 8px;
	@media (max-width: 640px) {
		margin-top: 10px;
		margin-bottom: 6px;
	}
`;

const CompleteButton = styled(StyledButton)`
	align-self: center;
	width: 100%;
	max-width: 400px;
`;

const KlarnaButton = styled(StyledButton)`
	color: ${({ theme }) => theme.accent};
	justify-content: left;
	background-color: transparent;
	border: 1px solid #eaeaea;
	padding-left: 15px;
`;

// Using OLD API

function KlarnaPageHighlightPlus(): JSX.Element {
	const navigate = useNavigate();
	const { abbrv, acceptNewsLetter } = useParams<{
		abbrv: string;
		acceptNewsLetter: string;
	}>();
	const [selectedCategory, setSelectedCategory] = useState<
		KlarnaPaymentsTypes.PaymentMethodCategory | undefined
	>();
	const [orderIdempotencyId, setOrderIdempotencyId] = useState<string | undefined>();
	const [creatingOrderInCee, setCreatingOrderInCee] = useState<boolean>(false);
	const [error, setError] = useState<AlertError | null>(null);
	const {
		order,
		setOrder,
		tokens,
		paymentsSession,
		setPaymentsSession,
		products,
		firebaseFirestore,
		firebaseFunctions,
		setErrorPage,
	} = useApp();
	const isPaymentsSessionCreationRunning = useRef<boolean>(false);
	const isCeeCreateOrderRunning = useRef<boolean>(false);
	const docRef = abbrv && firebaseFirestore ? doc(firebaseFirestore, `/teams/${abbrv}`) : null;
	const [team, teamLoading] = useDocumentOnce<POPATypes.Team | null>(
		docRef as DocumentReference<POPATypes.Team | null>
	);
	const initializedPaymentCategoryNames = useRef(new Set<string>());
	const [blockedPaymentMethodIds, setBlockedPaymentMethodIds] = useState<string[]>([]);
	const timeouts = useRef<NodeJS.Timeout[]>([]);
	const authorizationSuccessResponses = useRef(
		new Map<string, KlarnaPaymentsTypes.AuthorizeResponseData>()
	);

	const getHasAccessToHighlightsPlus = () => {
		const { HasAccessUntil } =
			(products && products[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION]) || {};
		const hasAccess = HasAccessUntil ? new Date(HasAccessUntil) > new Date() : false;

		return hasAccess;
	};

	function getButtonText(): string {
		if (
			!getHasAccessToHighlightsPlus() &&
			products?.[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION]?.Status === 'Cancelled'
		) {
			return 'Återaktivera abonnemanget';
		}
		if (
			getHasAccessToHighlightsPlus() &&
			products?.[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION]?.Status !== 'Cancelled'
		) {
			return 'Ändra betalsätt';
		}

		return 'Starta abonnemanget';
	}

	function cleanup() {
		try {
			timeouts.current.forEach((t) => clearTimeout(t));
		} catch (e) {
			/* ignore cleanup errors */
		}
	}

	useEffect(() => {
		setOrderIdempotencyId(uuidv4());
		return cleanup;
	}, []);

	useEffect(() => {
		async function createSession() {
			if (
				(!abbrv || (abbrv && team)) &&
				!isPaymentsSessionCreationRunning.current &&
				tokens &&
				!paymentsSession &&
				orderIdempotencyId
			) {
				isPaymentsSessionCreationRunning.current = true;
				const newOrder = Payments.createOrder(
					'Highlights Plus',
					3500,
					'SEK',
					'SE',
					'sv-SE',
					team && team.data()?.name ? team.data()?.name || '' : ''
				);
				setOrder(newOrder);
				const newPaymentsSession = await Payments.createSession(
					tokens.PersonId,
					tokens.accessToken,
					newOrder
				);
				setPaymentsSession(newPaymentsSession);
				isPaymentsSessionCreationRunning.current = false;
			}
		}
		createSession();
	}, [
		abbrv,
		setPaymentsSession,
		setOrder,
		tokens,
		paymentsSession,
		orderIdempotencyId,
		team,
		teamLoading,
	]);

	useEffect(() => {
		async function doAsync() {
			if (paymentsSession) {
				await Payments.init(paymentsSession);
				paymentsSession.paymentMethodCategories.forEach((category) => {
					if (!initializedPaymentCategoryNames.current.has(category.identifier)) {
						Payments.loadWidget(
							`${WIDGET_CONTAINER_ID}${category.identifier}`,
							category.identifier
						);
						initializedPaymentCategoryNames.current.add(category.identifier);
					}
				});
			}
		}
		doAsync();
	}, [paymentsSession]);

	const onCreateOrderClicked = async () => {
		if (
			!isCeeCreateOrderRunning.current &&
			selectedCategory &&
			order &&
			tokens &&
			orderIdempotencyId &&
			firebaseFunctions
		) {
			setError(null);
			logger.debug(
				'onCreateOrderClicked, isCeeCreateOrderRunning.current',
				isCeeCreateOrderRunning.current
			);
			isCeeCreateOrderRunning.current = true;
			// const unblock = history.block(false);

			try {
				let authorizationResponse = authorizationSuccessResponses.current.get(
					selectedCategory.identifier
				);
				if (!authorizationResponse) {
					authorizationResponse = await Payments.authorize(
						selectedCategory.identifier,
						order
					);
					if (!authorizationResponse.show_form) {
						// The payment method is not working, hide the button for the payment
						// method, and the payment widget for that payment method entirely
						// TODO hide the form and button
						setBlockedPaymentMethodIds([
							...blockedPaymentMethodIds,
							selectedCategory.identifier,
						]);
						setSelectedCategory(undefined);
						setError({
							severity: 'error',
							title: 'Betalsättet fungerar inte just nu',
							text: 'Du kan prova ett annat betalsätt eller försöka igen senare.',
						});
						throw new Error();
					}

					if (authorizationResponse.error) {
						setError({
							severity: 'info',
							title: 'Det finns fält som måste rättas i betalformuläret.',
						});
						throw new Error();
					}

					if (!authorizationResponse.approved) {
						setError({
							severity: 'warning',
							title: 'Betalningen avbröts eller nekades av betalningsförmedlaren',
							text: 'Du kan försöka igen om du själv avbröt betalningen, eller prova ett annat betalsätt.',
						});
						throw new Error();
					}

					if (!authorizationResponse.authorization_token) {
						setError({
							severity: 'error',
							title: 'Ett okänt fel uppstod. Försök igen senare.',
						});
						throw new Error();
					}

					authorizationSuccessResponses.current.set(
						selectedCategory.identifier,
						authorizationResponse
					);
				}

				try {
					setCreatingOrderInCee(true);

					/**
					 * ERROR TESTING
					 */
					// await new Promise((resolve, reject) => {
					// 	setTimeout(() => reject(new Error('TEST TWO')), 5000);
					// 	// setTimeout(() => {
					// 	// 	setAppError({
					// 	// 		title: 'Oväntat fel',
					// 	// 		message: 'Tyvärr har ett oväntat fel uppstått. Försök igen senare.',
					// 	// 	});
					// 	// 	unblock();
					// 	// 	history.replace(`/error`);
					// 	// }, 5000);
					// });
					/**
					 * END ERROR TESTING
					 */

					if (!authorizationResponse.authorization_token) {
						throw new Error('Klarna authorization not completed');
					}

					const ceeOrder =
						!products || !getHasAccessToHighlightsPlus()
							? await CEE.createOrderWithAuthToken(
									tokens.PersonId,
									tokens.accessToken,
									order,
									authorizationResponse.authorization_token,
									orderIdempotencyId,
									(team && String(team.data()?.everySportId)) || undefined,
									acceptNewsLetter === 'on'
							  )
							: await CEE.createCustomerTokenWithAuthToken(
									tokens.PersonId,
									tokens.accessToken,
									order,
									authorizationResponse.authorization_token,
									orderIdempotencyId,
									(team && String(team.data()?.everySportId)) || undefined,
									acceptNewsLetter === 'on'
							  );
					logger.debug('Order created in CEE:', ceeOrder);
					setCreatingOrderInCee(false);
					// ORDER COMPLETED SUCCESSFULLY
					navigate(
						`/subscription-legacy/thank-you/${ceeOrder.order_id}${
							abbrv ? `/${abbrv}` : ''
						}`,
						{
							replace: true,
						}
					);
				} catch (err) {
					if (!(err instanceof CeeKlarnaIntegrationError)) {
						Sentry.captureException(err);
					} else {
						Sentry.withScope((scope) => {
							scope.setExtra(
								'CEE Error',
								(err as CeeKlarnaIntegrationError).getCeeError()
							);
							Sentry.captureException(err);
						});
					}

					// Handle unknown errors like network errors. Rethrow for top-level catch to release navigation block etc.
					if (!(err instanceof CeeKlarnaIntegrationError)) {
						setError({
							severity: 'error',
							title: 'Ett okänt fel har inträffat. Du kan försöka igen.',
						});
						throw err;
					}

					const e = err as CeeKlarnaIntegrationError;
					const ceeError = await e.getCeeError();

					switch (ceeError.Code) {
						/**
						 * Errors that should not need to be handled? Internal test codes.
						 */
						case CeeKlarnaIntegrationErrorCodes.SUBSCRIPTION_IS_NOT_ACTIVE:
							setErrorPage({
								title: 'Oväntat fel',
								message: 'Tyvärr har ett oväntat fel uppstått. Försök igen senare.',
							});
							// unblock();
							navigate(`/error`, {
								replace: true,
							});
							break;

						/**
						 * Can not recover from these errors in frontend. Navigate to error page to close the flow.
						 */
						case CeeKlarnaIntegrationErrorCodes.INVALID_BILLING_PERIOD:
						case CeeKlarnaIntegrationErrorCodes.SUBSCRIPTION_IS_MISSING:
						case CeeKlarnaIntegrationErrorCodes.CUSTOMER_TOKEN_ORDER_IS_MISSING:
						case CeeKlarnaIntegrationErrorCodes.SUBSCRIPTION_IS_MISSING_END_DATE:
						case CeeKlarnaIntegrationErrorCodes.DUPLICATE_PERSONS_FOUND:
						case CeeKlarnaIntegrationErrorCodes.INVALID_ORDER_LINES:
						case CeeKlarnaIntegrationErrorCodes.ORDER_NOT_ACCEPTED:
							setErrorPage({
								title: 'Oväntat fel',
								message: 'Tyvärr har ett oväntat fel uppstått. Försök igen senare.',
							});
							// unblock();
							navigate(`/error`, {
								replace: true,
							});
							break;

						/**
						 * User can retry immediately. Let top level catch unblock.
						 */
						case CeeKlarnaIntegrationErrorCodes.ORDER_ID_IS_MISSING:
						case CeeKlarnaIntegrationErrorCodes.CUSTOMER_TOKEN_IS_MISSING:
							setError({
								severity: 'error',
								title: 'Ett okänt fel har inträffat. Du kan försöka igen.',
							});
							throw err as Error;

						/**
						 * The user already has an active subscription, try to sync subscription state in backend
						 * and let the top level of app navigate to Thank You page if subscription is deemed active
						 */
						case CeeKlarnaIntegrationErrorCodes.SUBSCRIPTION_IS_NOT_CANCELLED:
						case CeeKlarnaIntegrationErrorCodes.DUPLICATE_SUBSCRIPTION_PERIODS_FOUND:
						case CeeKlarnaIntegrationErrorCodes.TOO_EARLY_FOR_NEW_ORDER:
						case CeeKlarnaIntegrationErrorCodes.SUBSCRIPTION_IS_NOT_NEW_OR_CANCELLED:
							setError({
								severity: 'info',
								title: 'Det verkar som om du redan har en prenumeration hos oss. Vi försöker lösa din prenumerationsstatus automatiskt. Det kan ta upp till 30 sekunder.',
							});
							try {
								const res = await httpsCallable(
									firebaseFunctions,
									'refreshSubscriptionState'
								)();
								const subscription = res.data as CEETypes.SubscriptionStatus;
								if (
									subscription &&
									subscription.End &&
									new Date(subscription.End).getTime() > new Date().getTime()
								) {
									// Success, there is new subscription status. Redirect to start page for the "You already have a subscription" dialog.
									// unblock();
									navigate(`/`, {
										replace: true,
									});
								} else {
									throw err as Error;
								}
							} catch (callableError) {
								setErrorPage({
									title: 'Oväntat fel',
									message:
										'Tyvärr har ett oväntat fel uppstått. Försök igen senare.',
								});
								navigate(`/error`, {
									replace: true,
								});
							}
							break;

						/**
						 * An unknown error has occurred. Wait a while and see if the subscription webhook will get subscription data.
						 */
						case CeeKlarnaIntegrationErrorCodes.UNSPECIFIED_ERROR:
						default: {
							setError({
								severity: 'info',
								title: 'Ett okänt fel har inträffat. Systemet försöker lösa det automatiskt, det kan ta upp till 30 sekunder.',
							});
							// Timeout is async so can't throw error in here, unblock inside as needed
							const timeOut = setTimeout(() => {
								setError({
									severity: 'error',
									title: 'Ett okänt fel har inträffat. Du kan försöka igen.',
								});
								setCreatingOrderInCee(false);
								// unblock();
							}, 30000);
							timeouts.current.push(timeOut);
						}
					}
				}
			} catch (e) {
				logger.debug('Create order error:', e);
				setCreatingOrderInCee(false);
				// unblock();
			} finally {
				logger.debug('Create order finished');
				isCeeCreateOrderRunning.current = false;
			}
		}
	};

	const onClickChoosePaymentMethod = async (
		category: KlarnaPaymentsTypes.PaymentMethodCategory
	): Promise<void> => {
		if (selectedCategory !== category) {
			setError(null);
			setSelectedCategory(category);
			if (!order) {
				throw new Error('No order.');
			}
		}
	};

	const filteredPaymentCategories =
		paymentsSession?.paymentMethodCategories.filter(
			(m) => !blockedPaymentMethodIds.includes(m.identifier)
		) || [];

	const isPaymentMethodAvailable = filteredPaymentCategories.length > 0;

	return (
		<Container role="document" className="KlarnaPage">
			<HighlightsPlusInfoCard />

			<Card contentMaxWidth={606} leftAligned paddingTop={60} flex>
				{/* {error && (
					<Alert severity={error.severity} style={{ marginBottom: 26 }}>
						{error.title && <AlertTitle>{error.title}</AlertTitle>}
						{error.text}
					</Alert>
				)} */}
				{error && <KlarnaAlert error={error} />}

				{!creatingOrderInCee && isPaymentMethodAvailable && (
					<>
						<H3>Direktbetalning</H3>
						<P>Betala med ett klick</P>
					</>
				)}

				{!isPaymentMethodAvailable && !isPaymentsSessionCreationRunning && (
					<P>
						Tyvärr finns det inga betalsätt tillgängliga för tillfället. Kom tillbaka
						lite senare.
					</P>
				)}

				{!creatingOrderInCee &&
					filteredPaymentCategories.map(
						(category): JSX.Element => (
							<KlarnaButton
								disabled={creatingOrderInCee}
								key={category.identifier}
								name={category.identifier}
								onClick={() => onClickChoosePaymentMethod(category)}
								style={
									category.name === selectedCategory?.name
										? {
												border: '1px solid rgba(83, 38, 129, 0.5)',
												boxShadow: '0px 4px 6px rgb(0 0 0 / 10%)',
										  }
										: {}
								}
							>
								{category?.asset_urls?.standard && (
									<img
										src={category.asset_urls.standard}
										alt="Klarna"
										style={{ marginRight: 10, marginBottom: 2 }}
									/>
								)}
								{category.name}
							</KlarnaButton>
						)
					)}

				{creatingOrderInCee && (
					<Card contentMaxWidth={606}>
						<Spinner />
						<H4>Din order hanteras</H4>
						<P>Det kan ta några sekunder.</P>
					</Card>
				)}

				{paymentsSession &&
					(paymentsSession.paymentMethodCategories || []).map((category) => (
						<Klarna
							id={`${WIDGET_CONTAINER_ID}${category.identifier}`}
							key={category.identifier}
							style={{
								display:
									selectedCategory?.identifier === category.identifier &&
									!creatingOrderInCee
										? 'block'
										: 'none',
							}}
						/>
					))}

				<>
					<div style={{ height: 40 }} />
					<CompleteButton
						name="create_order"
						onClick={onCreateOrderClicked}
						disabled={teamLoading || !selectedCategory}
						style={{
							display: !creatingOrderInCee ? 'block' : 'none',
						}}
					>
						{getButtonText()}
					</CompleteButton>
				</>
			</Card>
		</Container>
	);
}

export default KlarnaPageHighlightPlus;
