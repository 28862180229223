import React from 'react';
import styled from 'styled-components';
import { fileEndingWebpOrJpeg } from '@utils/webp-support';

export const HuddleImageStyled = styled.div`
	flex-shrink: 0;
	background-image: ${(props: { abbrv: string }) =>
		`url('/g/thank-you-${props.abbrv}@2x.${fileEndingWebpOrJpeg}')`};
	background-position: center 70%;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 300px;
	@media (max-width: 640px) {
		height: 152px;
		background-image: ${(props) =>
			`url('/g/thank-you-${props.abbrv}.${fileEndingWebpOrJpeg}')`};
	}
	@media (min-width: 1280px) {
		background-position: center 50%;
		height: 480px;
		background-image: ${(props) =>
			`url('/g/thank-you-${props.abbrv}@3x.${fileEndingWebpOrJpeg}')`};
	}
`;

export default function HuddleImage({ abbrv = '' }: { abbrv: string }): JSX.Element {
	return <HuddleImageStyled abbrv={abbrv} />;
}
