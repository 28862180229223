import React from 'react';
import styled from 'styled-components';
import Card from '@components/Card';
import { PRODUCTS } from '@data/constants';

const { HIGHLIGHTS_PLUS } = PRODUCTS;

const H2 = styled.h2.attrs({ role: 'heading' })`
	margin: 0;
	padding: 0;
	font-size: 30px;
	margin-bottom: 10px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	color: #532681;
	@media (max-width: 640px) {
		font-size: 22px;
	}
`;

const P = styled.p`
	color: #101010;
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	font-family: Gilroy-Regular;
	font-weight: normal;
	font-style: normal;
	@media (max-width: 640px) {
		font-size: 13px;
	}
`;

export default function HighlightsPlusInfoCard(): JSX.Element {
	return (
		<Card className="HighlightsPlusInfo" contentMaxWidth={498} dark>
			<H2>Highlights Plus</H2>
			<P>
				Ett abonnemang kostar {HIGHLIGHTS_PLUS.monthlyCost} kr/månad och är utan
				bindningstid. Highlights Plus ger dig tillgång till höjdpunkter från Allsvenskan och
				Superettan direkt under matcherna. Det gäller alla 30 omgångar av ligorna.
			</P>
		</Card>
	);
}
