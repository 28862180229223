import React, { useEffect, useState, useRef } from 'react';
import KlarnaOrder from '@klarna/payments/class/KlarnaOrder';
import Payments from '@klarna/payments';
import * as Sentry from '@sentry/react';
import { KlarnaPaymentsTypes } from '@klarna/payments/types.d';
import { useApp } from '@contexts/app';
import { Logger } from '@utils/logger';
import Card from '@components/Card';
import Spinner from './Spinner';
import { H4 as PartH4 } from '@components/Parts';
import styled, { css } from 'styled-components';
import { CEETypes } from '../api/cee/types';
import CtaButton from './CtaButton';
import { ErrorMessages } from '@data/constants';
import { AlertError } from '../types/types';
import { Order } from '@hooks/useOrder';

const logger = new Logger('KlarnaCard');
const WIDGET_CONTAINER_ID = 'Klarna-payments-container';

const H3 = styled.h3`
	margin: 0 0 4px;
	padding: 0;
	font-size: 16px;
	font-weight: bold;
	color: ${({ theme }) => theme.textColor};
`;

const P = styled.p`
	color: ${({ theme }) => theme.textColor};
	margin: 5px 0 15px 0;
	font-size: 16px;
`;

export const Klarna = styled.div`
	margin-top: 20px;
`;

const H4 = styled(PartH4)`
	color: ${({ theme }) => theme.textColor};
	align-self: center;
	margin-top: 16px;
	margin-bottom: 8px;
	@media (max-width: 640px) {
		margin-top: 10px;
		margin-bottom: 6px;
	}
`;
const KlarnaButton = styled(CtaButton)<{ selected: boolean }>`
	color: ${({ theme }) => theme.textColor};
	justify-content: left;
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.divider};
	padding-left: 15px;
	max-width: none;
	${(props) =>
		props.selected &&
		css`
			border: 1px solid ${props.theme.primary};
			box-shadow: 0px 4px 6px rgb(0 0 0 / 10%);
		`}
`;

interface Props {
	onKlarnaOrderCompleted: (
		authorizationResponse: KlarnaPaymentsTypes.AuthorizeResponseData
	) => Promise<void>;
	buttonText: string;
	order: Order;
	product: CEETypes.CEEProduct;
	setError: (error: AlertError | null) => void;
	paymentDetails?: React.ReactNode;
}

const KlarnaCard = ({
	order,
	product,
	onKlarnaOrderCompleted,
	buttonText,
	setError,
	paymentDetails = null,
}: Props) => {
	const { tokens, paymentsSession, setPaymentsSession } = useApp();
	const [selectedCategory, setSelectedCategory] = useState<
		KlarnaPaymentsTypes.PaymentMethodCategory | undefined
	>();
	const isPaymentsSessionCreationRunning = useRef<boolean>(false);
	const initializedPaymentCategoryNames = useRef(new Set<string>());
	const [blockedPaymentMethodIds, setBlockedPaymentMethodIds] = useState<string[]>([]);
	const [creatingOrderInCee, setCreatingOrderInCee] = useState<boolean>(false);
	const [klarnaOrder, setKlarnaOrder] = useState<KlarnaOrder | null>(null);
	const isCeeCreateOrderRunning = useRef<boolean>(false);
	const authorizationSuccessResponses = useRef(
		new Map<string, KlarnaPaymentsTypes.AuthorizeResponseData>()
	);

	useEffect(() => {
		if (!klarnaOrder) {
			const newKlarnaOrder = Payments.createOrderNew(
				product.Name,
				order.ceeOrder.TotalAmount,
				order.ceeOrder.TotalVatAmount,
				product.PricePlans[0].VatRatePercent,
				product.PricePlans[0].PriceCurrency,
				'SE',
				'sv-SE'
			);
			setKlarnaOrder(newKlarnaOrder);
		}
	}, [klarnaOrder, order, product]);

	useEffect(() => {
		async function createSession() {
			if (tokens && order && !isPaymentsSessionCreationRunning.current && !paymentsSession) {
				isPaymentsSessionCreationRunning.current = true;
				const newPaymentsSession = await Payments.createSessionNew(
					tokens.PersonId,
					tokens.accessToken,
					order.paymentInfo
				);
				setPaymentsSession(newPaymentsSession);
				isPaymentsSessionCreationRunning.current = false;
			}
		}
		createSession();
	}, [setPaymentsSession, tokens, paymentsSession, order]);

	useEffect(() => {
		async function initPayment() {
			if (paymentsSession) {
				try {
					await Payments.init(paymentsSession);
					paymentsSession.paymentMethodCategories.forEach((category) => {
						if (
							!initializedPaymentCategoryNames.current.has(category.identifier) &&
							category.identifier !== 'pay_over_time'
						) {
							Payments.loadWidget(
								`${WIDGET_CONTAINER_ID}${category.identifier}`,
								category.identifier
							);
							initializedPaymentCategoryNames.current.add(category.identifier);
						}
					});
				} catch (e) {
					setError(ErrorMessages.klarna);
					Sentry.captureException(e);
				}
			}
		}
		initPayment();
	}, [paymentsSession, setError]);

	const onCreateOrderClicked = async () => {
		if (!isCeeCreateOrderRunning.current && selectedCategory && klarnaOrder) {
			setError(null);
			logger.debug(
				'onCreateOrderClicked, isCeeCreateOrderRunning.current',
				isCeeCreateOrderRunning.current
			);
			isCeeCreateOrderRunning.current = true;
			/*
				FIXME Navigation blocking is not supported in React-router-dom ver 6.4.5
				Re-add blocking once it's supported again, see:	https://github.com/remix-run/react-router/issues/8139
				const unblock = history.block(false);
			*/
			try {
				let authorizationResponse = authorizationSuccessResponses.current.get(
					selectedCategory.identifier
				);
				if (!authorizationResponse) {
					authorizationResponse = await Payments.authorize(
						selectedCategory.identifier,
						klarnaOrder
					);
					if (!authorizationResponse.show_form) {
						// The payment method is not working, hide the button for the payment
						// method, and the payment widget for that payment method entirely
						// TODO hide the form and button
						setBlockedPaymentMethodIds([
							...blockedPaymentMethodIds,
							selectedCategory.identifier,
						]);
						setSelectedCategory(undefined);
						setError({
							severity: 'error',
							title: 'Betalsättet fungerar inte just nu',
							text: 'Du kan prova ett annat betalsätt eller försöka igen senare.',
						});
						throw new Error();
					}

					if (authorizationResponse.error) {
						setError({
							severity: 'info',
							title: 'Betalningen avbröts',
							text: 'Det finns fält som måste rättas i betalformuläret.',
						});
						throw new Error();
					}

					if (!authorizationResponse.approved) {
						setError({
							severity: 'warning',
							title: 'Betalningen avbröts eller nekades av betalningsförmedlaren',
							text: 'Du kan försöka igen om du själv avbröt betalningen, eller prova ett annat betalsätt.',
						});
						throw new Error();
					}

					if (!authorizationResponse.authorization_token) {
						setError(ErrorMessages.default);
						throw new Error();
					}

					authorizationSuccessResponses.current.set(
						selectedCategory.identifier,
						authorizationResponse
					);
				}

				try {
					setCreatingOrderInCee(true);
					await onKlarnaOrderCompleted(authorizationResponse);
				} catch (callbackError) {
					console.error('Completing order failed!', callbackError);
				} finally {
					setCreatingOrderInCee(false);
				}
			} catch (e) {
				logger.debug('Create order error:', e);
				setCreatingOrderInCee(false);
				// unblock(); FIXME Navigation blocking is not supported in v6, see comment above
			} finally {
				isCeeCreateOrderRunning.current = false;
			}
		}
	};

	const onClickChoosePaymentMethod = async (
		category: KlarnaPaymentsTypes.PaymentMethodCategory
	): Promise<void> => {
		if (selectedCategory !== category) {
			setError(null);
			setSelectedCategory(category);
			if (!klarnaOrder) {
				throw new Error('No order.');
			}
		}
	};

	const filteredPaymentCategories =
		paymentsSession?.paymentMethodCategories.filter(
			(m) =>
				!blockedPaymentMethodIds.includes(m.identifier) &&
				// TODO Filter out payment method until CEE handles it
				m.identifier !== 'pay_over_time'
		) || [];

	const isPaymentMethodAvailable = filteredPaymentCategories.length > 0;

	return (
		<>
			{!creatingOrderInCee && isPaymentMethodAvailable && (
				<>
					<H3>Direktbetalning</H3>
					<P>Betala med ett klick</P>
				</>
			)}

			{!isPaymentMethodAvailable && !isPaymentsSessionCreationRunning && (
				<P>
					Tyvärr finns det inga betalsätt tillgängliga för tillfället. Kom tillbaka lite
					senare.
				</P>
			)}

			{!creatingOrderInCee && (
				<>
					{filteredPaymentCategories.map((category) => (
						<KlarnaButton
							disabled={creatingOrderInCee}
							key={category.identifier}
							name={category.identifier}
							onClick={() => onClickChoosePaymentMethod(category)}
							selected={category.name === selectedCategory?.name}
						>
							{category?.asset_urls?.standard && (
								<img
									src={category.asset_urls.standard}
									alt="Klarna"
									style={{ marginRight: 10, marginBottom: 2 }}
								/>
							)}
							{category.name}
						</KlarnaButton>
					))}
				</>
			)}

			{creatingOrderInCee && (
				<Card contentMaxWidth={606}>
					<Spinner />
					<H4>Din order hanteras</H4>
					<P>Det kan ta några sekunder.</P>
				</Card>
			)}

			{paymentsSession &&
				(filteredPaymentCategories || []).map((category) => (
					<div
						style={{
							display:
								selectedCategory?.identifier === category.identifier &&
								!creatingOrderInCee
									? 'block'
									: 'none',
						}}
						key={category.identifier}
					>
						<Klarna id={`${WIDGET_CONTAINER_ID}${category.identifier}`} />
						{paymentDetails}
					</div>
				))}
			{!creatingOrderInCee && (
				<>
					<div style={{ height: 40 }} />
					<CtaButton
						name="create_order"
						onClick={onCreateOrderClicked}
						disabled={!selectedCategory}
					>
						{buttonText}
					</CtaButton>
				</>
			)}
		</>
	);
};

export default KlarnaCard;
