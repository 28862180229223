import styled from 'styled-components';

const CtaButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 400px;
	height: 50px;
	color: ${(props) => props.theme.topbarTextColor};
	background-color: ${(props) => props.theme.cta};
	border-radius: 4px;
	border-width: 0px;
	font-family: Gilroy-Bold;
	font-weight: normal;
	font-style: normal;
	font-size: 16px;
	cursor: pointer;
	align-self: center;
	&[disabled] {
		color: #c0c0c0;
		background-color: ${(props) => props.theme.disabledBackground};
	}
	@media (max-width: 640px) {
		font-size: 14px;
		height: 44px;
	}
`;

export default CtaButton;
