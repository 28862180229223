/**
 * DOM utilities.
 */

/**
 * Creates a new script element.
 */
export function createScript(src: string, id?: string): HTMLScriptElement {
	const scriptEl = document.createElement('script');

	if (id) {
		scriptEl.setAttribute('id', id);
	}

	scriptEl.setAttribute('src', src);
	scriptEl.setAttribute('async', '');

	return scriptEl;
}

export default {
	createScript,
};
