import React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form<{
	name?: string;
}>``;

function handleFormSubmit(event: React.FormEvent): void {
	// TODO: Do something...
	event.preventDefault();
}

/**
 * Form component.
 * TODO: Describe component.
 * TODO: Add some action!
 */
export default function Form({
	name = undefined,
	children,
}: React.PropsWithChildren<{
	name?: string;
}>): JSX.Element {
	return (
		<StyledForm role="form" name={name} onSubmit={handleFormSubmit}>
			{children}
		</StyledForm>
	);
}
