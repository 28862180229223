/**
 * HTTP utilities.
 */

import { Logger } from '@utils/logger';

const logger = new Logger('HTTP');

/**
 * Returns an object with all URL parameters.
 */
export function getUrlParams(url?: string): Record<string, string> {
	const urlObj = url ? new URL(url) : window.location;
	const params: Record<string, string> = {};

	urlObj.search
		.slice(1)
		.split('&')
		.forEach((entry: string) => {
			const tuple = entry.split('=', 2);
			const name = decodeURIComponent(tuple[0]);

			params[name] = tuple.length > 1 ? decodeURIComponent(tuple[1]) : '';
		});

	return params;
}

/**
 * Makes an HTTP request, with GET as default method.
 * This is basically a wrapper around `fetch` that adds logging.
 */
export async function makeRequest(uri: string, opts?: RequestInit): Promise<Response> {
	try {
		logger.debug(`${opts?.method?.toUpperCase() || 'GET'} request to "${uri}":`, opts);
	} catch (e) {
		/* ignore logging errors */
	}

	const resp = await fetch(uri, opts);

	try {
		logger.debug(`Response from "${resp.url}":`, {
			status: resp.status,
			statusText: resp.statusText,
			contentType: resp.headers?.get('Content-Type'),
		});

		if (!resp.ok) {
			logger.warn('Response not OK!', uri);
		}
	} catch (e) {
		/* ignore logging errors */
	}

	return resp;
}

export default {
	getUrlParams,
	makeRequest,
};
