import { useApp } from '@contexts/app';
import { PRODUCTS } from '@utils/constants';

export default function useIsSubscriber(): boolean {
	const { products } = useApp();
	const { End, Status } = (products && products[PRODUCTS.HIGHLIGHTS_PLUS_SUBSCRIPTION]) || {};

	const isSubscriber =
		(products && End && new Date(End) >= new Date() && Status && Status === 'Active') || false;
	return isSubscriber;
}
